/**
 * Panel Loader Styles
 */

.panel-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #01447b;
  font-weight: 500;
  padding: 2rem 0;

  p {
    margin-bottom: 0;
  }

  svg {
    width: 3.25em;
    transform-origin: center;
    animation: rotate4 3s linear infinite;
  }

  circle {
    fill: none;
    stroke: #01447b;
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
  }

  @keyframes rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dashoffset: -125px;
    }
  }
}
