@use './src/variables' as *;

.holiday-details-component-wrapper {
  font-family: $base-font;

  &--top-information {
    padding: 0;

    @media screen and (min-width: 768px) {
      padding: 14px;
    }

    p {
      margin: 0;
    }

    p + p {
      margin-top: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      // color: $main;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 300;
      // color: $secondary-light;
      margin: 10px 0;
    }
    .ratings-section {
      display: flex;
      align-items: center;

      .trip-logo {
        display: flex;
        margin-left: 20px;
        align-items: center;

        .logo {
          height: 16px;
          margin-right: 5px;
        }
        .fullrate {
          height: 14px;
          margin-left: 3px;
        }
      }

      .rating {
        margin-left: 0;
      }

      span {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 300;
        color: $grey-light;
      }
    }
  }

  &--slider {
    margin: 30px 0;
    min-width: 300px;

    .image-carousel {
      .splide__track {
        border-radius: 10px;
      }

      .navigation-wrapper {
        border-radius: 5px;
      }
    }
  }

  &--footer-links {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      padding: 0 10px;
    }

    .link-wrapper {
      display: flex;
      align-items: center;
      text-decoration: none;

      .link-icon {
        width: 16px;
        height: 16px;
      }

      span {
        margin-left: 2px;
        font-size: 12px;
        font-weight: 300;
        // color: $main;
        border-bottom: solid 1px $main;

        @media screen and (min-width: 350px) {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
