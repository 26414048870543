@import '../../variables';

.autocomplete-container {
  position: relative;

  .autocomplete-custom-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    z-index: 2;

    .autocomplete-custom-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .autocomplete-custom-checkbox-checkmark {
      background-color: $white;
      border-radius: 2px;
      border: 1px solid $secondary;
      height: 24px;
      width: 24px;
      position: absolute;
      top: -7px;
      left: 0;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }
  }

  .autocomplete-custom-checkbox:checked ~ .autocomplete-custom-checkbox-checkmark {
    background-color: $main;
  }

  .autocomplete-custom-checkbox:checked ~ .autocomplete-custom-checkbox-checkmark:after {
    display: block;
  }

  .autocomplete-custom-checkbox-container .autocomplete-custom-checkbox-checkmark:after {
    height: 11px;
    left: 8px;
    top: 4px;
    width: 6px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .autocomplete-custom-checkbox-container.is-web-app .autocomplete-custom-checkbox-checkmark:after {
    top: 2px;
    left: 7px;
  }

  .custom-option-content {
    margin-left: 10px;
    cursor: pointer;
  }
}

.autocomplete-container-desktop {
  display: block;
}
.autocomplete-container-mobile {
  display: none;
}

@media (min-width: $screen-small) {
  .autocomplete-container {
    position: absolute;
    top: 85px;
    border: 1px solid $secondary;
    height: auto;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    background-color: $white;
    z-index: 1;
  }

  @media screen and (min-width: 1200px) {
    .autocomplete-container {
      width: 300px;
    }
  }

  .autocomplete-custom-checkbox-checkmark {
    height: 30px;
    width: 30px;
    top: -10px;
  }

  .autocomplete-custom-checkbox-container .autocomplete-custom-checkbox-checkmark:after {
    // left: 11px;
    // top: 7px;
    // width: 5px;
    // height: 10px;
    height: 11px;
    left: 8px;
    top: 4px;
    width: 6px;
  }
}

@media (max-width: $screen-small) {
  .autocomplete-container-desktop {
    display: none !important;
  }
  .autocomplete-container-mobile {
    display: block;
    box-shadow: 0px -4px 14px -3px rgba(50, 50, 50, 0.35);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .autocomplete-container {
    position: relative;
    width: 100%;
    top: 0px;
    padding: 0px;

    .autocomplete-custom-checkbox-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;
      z-index: 2;

      .autocomplete-custom-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .autocomplete-custom-checkbox-checkmark {
        background-color: $white;
        border-radius: 2px;
        border: 1px solid $secondary;
        height: 24px;
        width: 24px;
        position: absolute;
        top: -7px;
        left: 0;

        &:after {
          content: '';
          position: absolute;
          display: none;
        }
      }
    }

    // .autocomplete-custom-checkbox:checked ~ .autocomplete-custom-checkbox-checkmark {
    //   background-color: $main;
    // }

    .autocomplete-custom-checkbox:checked ~ .autocomplete-custom-checkbox-checkmark:after {
      display: block;
    }

    // .autocomplete-custom-checkbox-container .autocomplete-custom-checkbox-checkmark:after {
    //   left: 9px;
    //   top: 5px;
    //   width: 3px;
    //   height: 8px;
    //   border: solid $white;
    //   border-width: 0 3px 3px 0;
    //   transform: rotate(45deg);
    // }

    .custom-option-content {
      margin-left: 10px;
    }
  }
}
