.hwt-header {
  padding: 0.1rem 1.25rem;
}

/* @media screen and (min-width: 768px) {
  .hwt-header {
    margin-bottom: 2rem;
    padding: 1.5rem 1rem;
  }
} */

.hwt-header {
  padding: 10px;
  margin-bottom: 10px;
}

.wrapper {
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
}

@media screen and (min-width: 1400px) {
  .wrapper {
    padding: 0;
  }
}

.header-icons-section {
  display: flex;
  align-items: center;
}

.header .header__abta-wrap,
.header .header__atol-wrap {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.header .header__atol-wrap .header__atol-text {
  /* color: #01447b; */
  margin-left: 0.75rem;
  line-height: 1;
  font-size: 0.7rem;
  font-weight: 600;
}

@media screen and (min-width: 1024px) {
  .header .header__atol-wrap .header__atol-text {
    font-size: 0.8rem;
  }
}

.header .header__atol-wrap img {
  height: 40px;
}

.header .header__abta-wrap img {
  height: 30px;
}

@media screen and (min-width: 1024px) {
  .header .header__atol-wrap img {
    height: 50px;
  }

  .header .header__abta-wrap img {
    height: 40px;
  }
}

.header-logo a {
  display: flex;
  align-items: center;
}

.main-search {
  width: 100%;
  justify-content: center !important;
}

.booking-confirmed-icons {
  display: flex;
}
/*
.mobile-wrapper {
  display: none;
} */

.booking-confirmed-icons {
  gap: 1rem;
}

.main-search,
.menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-icons-section > .heart-icon-wrapper {
  margin-right: 36px;
}

.header-icons-section .menu-wrapper {
  border: 1px solid rgba(1, 68, 123, 0.2);
  padding: 16px 20px;
  border-radius: 40px;
}

.header-icons-section .menu-wrapper .header-icon + .header-icon {
  margin-left: 14px;
}

.main-search > .progress-bar-wrapper {
  width: 100%;
}

@media (max-width: 768px) {
  .wrapper {
    display: none;
  }

  .mobile-wrapper > .main-search,
  .header-logo,
  .header-icons-section,
  .menu-wrapper {
    padding: 10px 0;
  }

  .mobile-wrapper > .header-icons-section > .heart-icon-wrapper {
    margin-right: 0;
  }

  .noneBorder {
    border: none;
  }
}
