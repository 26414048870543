@import '../../variables';

.luggage-container {
  background-color: white;
  width: 100%;
  border: 1px solid $secondary-light;
  border-radius: 12px;
}
.luggage-container-child {
  padding: 25px;
  margin: 0 15px;
  position: relative;
  border: 1px solid $secondary-light;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  grid-template-columns: 20% 50% 30%;
  display: grid;
  align-items: center;
}
.luggage-container-child:last-child {
  margin-bottom: 25px;
  border-bottom: 1px solid $secondary-light;
  border-radius: 0 0 4px 4px;
}

.luggage-title {
  font-size: 14px;
  margin-top: 0px;
  color: $main;
}

.luggage-title > span {
  margin-left: 5px;
}

.luggage-icon {
  position: relative;
  top: 10px;
  margin-left: 8px;
}

.luggage-description {
  margin-left: 15px;
  font-size: 14px;
  color: $main;
}

.luggage-info {
  font-size: 12px;
  color: $main;
  text-decoration: underline;
  margin-left: 15px;
}

.luggage-option-title {
  font-weight: bold;
  font-size: 16px;
  color: $main;
}

.luggage-option-price {
  left: 20px;
  font-size: 24px;
  position: relative;
  color: $primary;
  font-weight: bold;
}

.luggage-option-price-perbag {
  position: absolute;
  top: 25px;
  width: 50px;
  font-size: 12px;
  left: 0px;
  color: $primary;
  font-weight: bold;
}

.luggage-option-inline-price {
  left: 20px;
  margin-right: 30px;
  font-size: 24px;
  position: relative;
  color: $primary;
  font-weight: bold;
  display: none;
}

.luggage-option-price-inline-perbag {
  top: -5px;
  width: 50px;
  margin-left: 5px;
  font-size: 12px;
  position: relative;
  left: 0px;
  color: $primary;
  font-weight: bold;
  display: none;
}

.luggage-stepper-container {
  position: absolute;
  right: 20px;
  top: 25px;
}

.luggage-option-info {
  opacity: 0;
  font-size: 12px;
  position: relative;
  left: 20px;
  color: $main;
  max-width: 110px;
  display: inline-block;
}

.luggage-divider {
  height: 1px;
  background-color: $secondary-light;
  width: 100%;
  display: none;
}

.animate-toFadeInAndOut {
  opacity: 0;
  display: inline-block;
  animation: fadeInOut 2s linear;
}

@-webkit-keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (min-width: 700px) {
  .luggage-option-info {
    width: auto;
    max-width: fit-content;
  }
}

@media (max-width: $screen-medium) {
  .luggage-container {
    border-radius: 0px;
    margin-top: 1rem;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    padding-bottom: 10px;
    margin-bottom: 1rem;
  }

  .luggage-container-child {
    width: calc(100% - 30px);
    padding: 50px;
    padding-top: 25px;
    padding-left: 25px;
    margin: 0 15px;
    position: relative;
    border: 1px solid $secondary-light;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }
  .luggage-option-price {
    display: none;
  }

  .luggage-option-inline-price {
    top: 5px;
  }

  .luggage-option-price-inline-perbag,
  .luggage-option-inline-price {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: auto;
  }
  .luggage-option-info {
    top: 10px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    text-align: left;
    display: inline-block;
  }
}

@media (max-width: $screen-small) {
  .luggage-container-child {
    grid-template-columns: 50% 50%;
    padding-bottom: 25px;
  }

  .luggage-option-info {
    grid-row-start: 2;
    left: 0;
  }
}
