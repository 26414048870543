@use './src/variables' as *;

.name-section-wrapper {
  padding: 20px 25px;
  font-family: $base-font;

  .main-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name-section {
      display: flex;
      align-items: center;

      .name {
        font-size: 20px;
        font-weight: bold;
        // color: $main;
      }
    }

    .map-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;

      img {
        height: 21px;
        width: 21px;
      }

      span {
        margin-left: 7px;
        // color: $main;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .location {
    // color: $secondary;
    font-size: 16px;
    font-weight: 300;
    margin: 10px 0;
    line-height: 18px;
  }

  .rating-container {
    display: flex;
    gap: 2em;
    align-items: center;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    &--map {
      @media screen and (max-width: 768px) {
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .tripadvisor-rating {
    display: flex;
    align-items: center;

    .trip-logo {
      height: 16px;
      margin-right: 5px;
      scale: 1.6;
    }

    .circle {
      width: 12px;
      height: 12px;
    }

    .circle + .circle {
      margin-left: 3px;
    }

    .review-details {
      font-size: 12px;
      font-weight: 300;
      color: $grey-light;
      margin-left: 10px;
      display: none;

      @media scren and (min-width: 1024px) {
        display: block;
      }
    }

    .fullrate {
      height: 14px;
      margin-left: 3px;
    }
  }

  @media (max-width: $screen-small) {
    padding: 20px 14px;
    .main-info-wrapper {
      .name-section {
        .name {
          font-size: 16px;
        }
      }
    }

    .location {
      font-size: 16px;
    }

    .tripadvisor-rating {
      .trip-logo {
        width: 24px;
        height: 15px;
      }

      .circle {
        width: 14px;
        height: 14px;
      }

      .fullrate {
        height: 14px;
      }
    }
  }
}
