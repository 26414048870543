@import '../../variables';

.room-container {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $secondary;
  border-radius: 4px;
  color: $main;
  padding: 0 12px 24px 12px;
  margin-bottom: 16px;

  .room-number {
    margin: 21px 0;
  }

  .selects-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .select-label {
      width: 100%;
    }
  }

  .button {
    width: 100px;
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 12px;
    height: auto;
  }
}

.room-grid-2-2 {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 5px;
  margin-bottom: 10px;
}

.room-child-age-title {
  margin-bottom: 10px;
}

.custom-dropdown-indicator {
  padding: 9px;
  position: relative;

  .custom-dropdown-indicator-icon {
    // border-color: #01447b;
    border-style: solid;
    border-width: 3px 3px 0 0;
    transform: rotate(135deg);
    height: 12px;
    width: 12px;
    position: absolute;
    top: 0;
    left: 2px;
  }
}

@media (max-width: $screen-medium) {
  .room-grid-2-2 {
    display: grid;
    grid-template-columns: 100%;
    gap: 5px;
    margin-bottom: 10px;
  }
}
