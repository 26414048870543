@import '../../variables';

.stepper {
  display: flex;

  .stepper__button {
    appearance: none;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--left {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: $secondary-light;
      color: $white;

      svg {
        position: relative;
        left: 2px;
      }
    }

    &--right {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      border: 1px solid transparent;
      background-color: $primary;
      color: $white;

      svg {
        position: relative;
        right: 1px;
      }
    }
  }

  .stepper__input {
    appearance: none;
    border: 1px solid $secondary-light;
    color: $main;
    font-family: $base-font;
    font-size: 14px;
    height: 30px;
    text-align: center;
    width: 60px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

// .loader-ring {
//   display: inline-block;
//   position: relative;
//   width: 54px;
//   height: 26px;
// }
// .loader-ring div {
//   box-sizing: border-box;
//   display: block;
//   position: absolute;
//   width: 30px;
//   height: 30px;
//   margin: 0px;
//   border: 4px solid black;
//   border-radius: 50%;
//   animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//   border-color: black transparent transparent transparent;
// }
// .loader-ring div:nth-child(1) {
//   animation-delay: -0.45s;
// }
// .loader-ring div:nth-child(2) {
//   animation-delay: -0.3s;
// }
// .loader-ring div:nth-child(3) {
//   animation-delay: -0.15s;
// }
// .loader-grid-auto {
//   grid-template-columns: auto;
// }
// @keyframes loader-ring {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
