@import '../../variables';

.select-parking-container {
  color: $main;
  width: 100%;
  border: 1px solid $secondary-light;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;

  @media screen and (max-width: 768px) {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }

  // Title
  .select-parking-title {
    font-size: 14px;
    margin-top: 0;

    @media screen and (min-width: 768px) {
      margin-top: 10px;
    }
  }

  .select-parking-title-text {
    margin-left: 10px;
  }

  .select-parking-icon {
    position: relative;
    top: 5px;

    @media screen and (min-width: 768px) {
      margin-left: 16px;
    }
  }

  // Child Styles (Mobile)
  .select-parking-container-child-mobile {
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px 15px;
    position: relative;
    border: 1px solid $secondary-light;
    border-radius: 4px;
    text-align: center;
    // margin: 1rem;
    margin-bottom: 1rem;

    &-active {
      border: 1px solid $primary;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }

    // Mobile Duration
    .select-parking-container-duration {
      margin-top: 0px;
      font-size: 14px;
      padding-bottom: 20px;
    }

    // Mobile Price
    .select-parking-container-price {
      margin-top: 0px;
      font-size: 18px;
      font-weight: bold;
      color: $primary;

      .select-parking-container-price__value {
        display: block;
        margin: 12px 0 24px;

        @media screen and (min-width: 768px) {
          margin: 0;
        }
      }
    }

    // Mobile Button
    // Button
    .select-parking-button-wrap {
      display: flex;
      justify-content: center;
    }

    .select-parking-button {
      font-weight: 700;
      border: 0;
      border-radius: 20px;
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      padding: 0px;
      height: 35px;
      flex: 1;
      color: $secondary-light;
      background-color: transparent;
      border: 1px solid $secondary-light;
      width: 100%;
      margin: 10px;

      @media screen and (min-width: 768px) {
        width: 130px;
        top: 20px;
        right: 15px;
      }

      &-active {
        border-color: $primary;
        color: $primary;
        background-color: $primary-light;
      }
    }
  }

  // Child Styles (Desktop)
  .select-parking-container-child {
    display: none;

    @media screen and (min-width: 768px) {
      display: grid;
    }

    width: calc(100% - 30px);
    padding: 10px 15px;
    margin: 5px 15px;
    position: relative;
    border: 1px solid $secondary-light;
    border-radius: 4px;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;

    // Selected State
    &-active {
      border: 1px solid $primary;
    }

    // Duration
    .select-parking-container-duration {
      font-size: 14px;
    }

    // Price
    .select-parking-container-price {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
      color: $primary;
      text-align: right;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
      }
    }

    // Button
    .select-parking-button-wrap {
      display: flex;
      justify-content: flex-end;
      grid-column-start: 4;
    }

    .select-parking-button {
      font-weight: 700;
      border: 0;
      border-radius: 20px;
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      padding: 0px;
      height: 35px;
      width: 100%;
      color: $secondary-light;
      background-color: transparent;
      border: 1px solid $secondary-light;
      margin: 10px;

      @media screen and (min-width: 768px) {
        width: 130px;
      }

      &-active {
        border-color: $primary;
        color: $primary;
        background-color: $primary-light;
      }
    }
  }
}
