@import '../../variables';

.board {
  border: 1px solid #99b4ca;
  border-radius: 4px;
  display: inline-block;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 12px 32px;
  background-color: $white;
  // color: $main;
  font-size: 12px;
  flex-grow: 1;
  width: 160px;
  text-align: center;
  cursor: pointer;

  .board-info {
    .price-bold {
      font-weight: bold;

      // &.yellow {
      //   color: $yellow;
      // }
    }
  }

  // &.selected {
  //   border-color: $yellow;
  //   background-color: $yellow-light;
  // }
}

@media (min-width: $screen-small) {
  .board {
    flex-grow: unset;
    width: auto;
    min-width: 160px;
    min-height: 55px;
  }
}
