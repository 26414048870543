@use '../../variables' as *;

.package-page {
  max-width: 1440px;
  margin: 0 auto;

  .package-page-wrapper {

    display: grid;
    grid-template-columns: 1fr 350px;
    margin: 50px 10px 30px;
    gap: 20px;   
    
    @media (max-width: '1400px') {
      flex-direction: row;
      grid-template-columns: 1fr;
      margin-top: 24px;
      gap:0px;
 
      .search-bar-container {
        padding: 0;
      }
    }    

    @media (max-width: '500px') {
      grid-template-columns: repeat(auto-fit, minmax(450px,1fr));
    }   
    @media (max-width: '450px') {
      grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    }   
    @media (max-width: '400px') {
      grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    }   

    @media (max-width: '320px') {
      grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    }       
    
    @media (max-width: '300px') {
      grid-template-columns: repeat(auto-fit, minmax(290px,1fr));
    }   
  //  @media (max-width: '400px') {
  //     grid-template-columns: 360px;
  //     margin-top: 24px;

  //     .search-bar-container {
  //       padding: 0;
  //     }
  //   }   
    
    .card-section {
      width: 100%;
    }



  }

  .package-page-aside {
    margin-top: 1rem;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  h1.package-page-title {
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  .package-page-location {
    // color: $secondary;
    margin-top: 0;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  /**
   * Page Grid
   */
  // .package-page-grid {

  //   @media screen and (min-width: 768px) {
  //     display: grid;
  //     gap: 2rem;
  //     grid-template-columns: 1fr 350px;
  //   }

  //   &--loader {
    
  //     @media screen and (max-width: 1100px) {
  //       display: grid;
  //       gap: 2rem;
  //       grid-template-columns: 1fr;
  //     }
  //   }
  // }

  .package-page-header {
    display: flex;
    flex-direction: column;
    border: 0;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
      padding: 0 0 2rem;
      margin-bottom: 0;
    }

    h1 {
      margin: 0;
    }
  }

  .package-page-ratings {
    display: flex;
    align-items: center;
    margin-bottom: 0rem;
    gap: 2rem;
  }

  .name-section-wrapper {
    padding: 0;

    @media screen and (min-width: 768px) {
      margin-left: 2rem;
    }
  }

  .package-page-hotel-divider {
    margin: 2rem 1rem;
    border-bottom: solid 1px $secondary-light;
  }

  /**
   * Hotel Details
   */
  .package-page-hotel-detail {
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid black;
    padding: 1.25rem;
    margin-top: 2rem;     
    overflow: hidden;

    .package-page-hotel-detail__copy {
      line-height: 1.5;
 
    }

    .package-page-hotel-facilities-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      padding: 0 1rem;
      margin-bottom: 0;
    }

    .simple-tabs-tab-content {
      margin-top: 2rem;
      overflow: hidden;

      .package-page-title {
        margin-top: 0;
      }
    }
  }

  /**
   * Make splide slides rounded
   */
  .splide__slide {
    border-radius: 0.5rem;

    img {
      border-radius: 0.5rem;
    }
  }
}
