@import '../../../variables';

.accordion-title {
  display: flex;
  gap: 0.5rem;
  // color: $main;
  font-size: 13px;
  font-weight: 600;
}

.accordion-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
  cursor: pointer;
}

.accordion-content {
  padding-inline-start: 0;

}

.accordion-content li {
  list-style: none;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 2rem;
  // color: $main;

  a {
    text-decoration: underline;
    // color: $main;
  }
}

.accordion-content-display {
  display: block;
}

@media screen and (min-width: 1024px) {

  .footer-links-mob {
    display: none;
  }
  
  .footer-links{
    display: block;
  }
  
  .accordion-dropdown-icon {
    display: none;
  }
  
  .accordion-item {
    display: block;
  }
  .accordion-content {
    display: flex;
    flex-direction: column;
  }

  .accordion-content li {
    text-align: left;
  }
}

@media screen and (max-width: 1023px) {

  .footer-links-mob {
    display: block;
  }

  .footer-links{
    display: none;
  }

}
