@use '../../variables' as *;

.validation-error-wrapper {
  background-color: white;
  max-width: 1400px;
  grid-template-columns: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 6px;
  padding: 24px 16px;
}

.validation-error-wrapper p {
  width: 100%;
}
.validation-error-wrapper li {
  margin-bottom: 5px;
}

@media (min-width: $screen-small) {
  .validation-error-wrapper {
    grid-template-columns: 70% 30%;

  }
}

.passengers-details-wrapper {
    max-width: 1400px;
    display: grid;
    grid-template-columns: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    .passengers-details-submit-button {
      font-size: 14px;
      padding: 12px 10px;
      color: $white;
      background-color: $button-default;
      font-weight: 700;
      border: 0;
      border-radius: 6px;
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      width: 100%;
    }
    .passengers-details-submit-button-mobile {
      display: block;
      margin: 32px 16px;
    }
    .passengers-details-submit-button-desktop {
      display: none;
    }
}

@media (min-width: $screen-small) {
  .passengers-details-wrapper {
    grid-template-columns: 70% 30%;

    .passengers-details-submit-button-mobile {
      display: none;
    }
    .passengers-details-submit-button-desktop {
      display: block;
    }
  }
}
