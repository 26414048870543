@import '../../variables';

.Fullscreen-cover {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  z-index: 10;
  background-color: $white;
}
