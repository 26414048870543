@import '../../variables';

.map-modal-content-wrapper {
  height: 100%;
  display: flex;
  position: relative;

  .map-modal-sidebar {
    position: absolute;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    // bottom: 40px;
    left: 8px;
    bottom: 24px;
    width: calc(100% - 16px);
    // width: 300px;

    .map-link {
      display: none;
    }

    .other-dates-btn {
      display: none;
    }

    .toggle {
      margin-bottom: 1.5rem;
    }

    .holiday-card-wrapper .image-carousel {
      border-radius: 0 !important;
    }

    .price-section {
      flex-wrap: wrap;
      padding-bottom: 0;

      .price-wrapper {
        margin-bottom: 1rem;
      }
    }

    &.mobile {
      .toggle {
        display: none;
      }

      .holiday-card-wrapper--carousel-wrapper {
        max-height: 100%;
        height: auto;

        .image-carousel {
          padding: 0;
          max-height: 100%;
          height: auto;
          .navigation-wrapper {
            border-radius: unset;

            .keen-slider {
              border-radius: 4px 0 0 4px;
            }
          }
        }
      }

      .holiday-card-wrapper {
        // max-width: 100%;
        width: 100%;
        background-color: $white;
        display: grid;
        grid-template-columns: 172px 1fr;

        .holiday-card-wrapper--details {
          border: none;
          justify-content: space-around;

          .name-section-wrapper {
            padding: 8px;
            border: none;
            .main-info-wrapper {
              .name-section {
                margin-bottom: 8px;
                .name {
                  font-size: 12px;
                  margin-bottom: 0;
                }
              }
            }
            .location {
              display: none;
            }
          }
          .holiday-details-wrapper {
            display: none;
          }
          .price-section {
            background-color: $white;
            align-items: center;
            flex-direction: row;
            padding: 8px;
            gap: 8px;
            align-items: center;

            .price-wrapper {
              margin-bottom: 0;
              .sale-price {
                font-size: 12px;
                margin-left: 0;
                img {
                  display: none;
                }
              }
            }
            .button {
              font-size: 12px;
              padding: 4px 8px;
            }
          }
        }
      }
    }

    @media (min-width: $screen-small) {
      // padding: 24px 0;
      padding-top: 24px;
      position: absolute;
      background-color: $white;
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      top: 0;
      left: 0;
      bottom: 0;

      .holiday-card-wrapper {
        padding-bottom: 1rem;
      }
    }
  }
}

.custom-marker {
  position: relative;
  top: -26px;
  left: -50%;
  z-index: 2;

  .marker-button {
    box-sizing: border-box;
    border: 2px solid $main;
    padding: 12px;
    border-radius: 6px;
    background-color: $white;
    font-size: 16px;
    // color: $main;
    font-weight: bold;
  }

  .triangle-left {
    position: absolute;
    top: 44px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-top: 12px solid #01447b;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;

    .inner-triangle {
      position: relative;
      top: -14px;
      left: -7px;
      width: 0;
      height: 0;
      border-top: 10px solid #ffffff;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }

  .shadow {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background-color: #4a779d;
    height: 12px;
    width: 32px;
    border-radius: 100%;
  }

  &.active {
    .marker-button {
      background-color: $main;
      color: $white;
    }
    .inner-triangle {
      border-top-color: $main;
    }
  }
}

.infoBox {
  //transform: translateY(10px) translateX(90px) !important;
  .holiday-details-component-wrapper--top-information {
    padding: 1rem;
  }

  @media screen and (min-width: 1200px) {
    //transform: translateY(-30px) translateX(0) !important;
  }

  .custom-info-window-content {
    background-color: $white;
    border-radius: 12px;
    // overflow: hidden;
    max-width: 300px;
    width: 100%;

    .splide__track {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .info-window-image {
      max-width: 300px;
      width: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .info-box-information-wrapper {
      padding-bottom: 1rem;

      .info-box-button-wrapper {
        padding: 0 1rem;
      }

      .holiday-details-wrapper--top-information {
        .title,
        .subtitle {
          font-size: 16px;
        }
      }

      .price {
        margin-left: 1rem;
        margin-bottom: 1rem;
        display: inline-block;
        font-size: 24px;
        font-weight: bold;
        // color: $main;
      }
    }
  }
}

.infoBox > img {
  position: absolute !important;
  margin: 6px !important;
}

@media (min-width: $screen-small) {
  .map-modal-content-wrapper {
    .holiday-card-wrapper {
      display: block;
      width: 100%;
      overflow-y: auto;

      .holiday-card-wrapper--details {
        border: none;
      }

      .holiday-card-wrapper--carousel-wrapper {
        max-height: 100%;
        height: auto;

        .image-carousel {
          padding: 24px;
          max-height: 100%;
          height: auto;

          .navigation-wrapper {
            .keen-slider {
              border-radius: 6px;
              min-height: 300px;
            }
          }
        }
      }
    }
  }
}

.test-map {
  min-height: 500px;
  height: 100%;
  width: 100%;
  border-radius: 12px;

  @media screen and (max-height: 1200px) {
    // max-height: 500px;
  }

  @media screen and (min-height: 1201px) {
    // height: 800px;
  }
}

.modal-content.map-modal-content {
  // height: 100%;
  // padding-bottom: 1rem;

  @media screen and (max-width: 1024px) {
    height: calc(100% - 56px);
  }

  @media screen and (min-width: 720px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    .image-carousel {
      .splide {
        height: 100%;
      }

      .splide__track,
      .splide__slide {
        height: 100% !important;
      }
    }
  }
}
