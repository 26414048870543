@import './src/variables';

.room-select-desktop-view {
  display: block;
  font-weight: 300;
}

.room-select-mobile-view {
  display: none;
  // font-weight: 300;
}

.room-select-mobile-con {
  position: fixed;
  top: 0;
  height: 100dvh;
  width: 100%;
  background: white;
  z-index: 1000;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.room-header-section {
  height: 60px;
  color : #01447b;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.room-header-section-text  {
  margin: 160x 0 !important;
}

.room-footer-section {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding: 1rem;
}

.room-footer-section button {
  height: 100%;
}

.room-header-section-close  {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 1px solid $main;
  cursor: pointer;
  background-image: url(../assets/close-icon.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
  position: absolute;
  left: 10px;
  top: 18px;
}

.room-select-overlay {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

@media (max-width: $screen-small) {
  .room-select-mobile-view {
    display: block;
  }
  .room-select-desktop-view {
    display: none;
  }
}

.rooms-container {

  box-shadow: 0px -4px 14px -3px rgba(50, 50, 50, 0.35);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  overflow: auto;
  .rooms-container__error {
    color: red;
    font-weight: 500;
  }
  .rooms-container__buttons {
    display: flex;

    .button {
      margin-bottom: 0;
      padding: 20px 10px;
      line-height: 0;
    }
  }
}
