.rating {
  display: flex;

  .star {
    width: 11px;
    height: 11px;
  }

  .star + .star {
    margin-left: 4px;
  }

  .circle {
    width: 12px;
    height: 12px;
  }

  .circle + .circle {
    margin-left: 3px;
  }
}
