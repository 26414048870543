@use './src/variables' as *;

.flight-details-wrapper {
  font-family: $base-font;
  padding: 10px 0;

  .flight-details--top-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .top-date-details {
      display: grid;
      grid-template-columns: 30px 1fr;

      img {
        width: 12px;
        height: 12px;
        margin: 2px 0 0 0;
      }

      .info-section {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 12px;
        }

        .sub-title {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .flight-details--main-section {
      order: 2;
      width: 100%;
      position: relative;

      .route-point {
        padding: 20px 1em 0 1em;
        // color: $main;
        font-size: 14px;
        font-weight: 300;
        margin: 0 0 0 3px;

        &--item {
          position: relative;
          .time {
            margin-left: 17px;
          }

          .place-name {
            margin-left: 20px;
            white-space: nowrap;
          }

          .flight-time {
            position: absolute;
            top: 29px;
            left: 17px;
            font-size: 12px;
            font-weight: 500;
            // color: $secondary-light;
          }

          &:first-child:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 44px;
            left: -14px;
            top: 15px;
            border-left: 1px solid $secondary-light;
          }
        }

        .route-point--item + .route-point--item {
          margin-top: 40px;
        }
      }
    }

    .flight-info {
      display: flex;
      flex-direction: column;

      .airline-logo {
        width: 100%;
        height: 10px;
        margin-bottom: 5px;
      }

      .airline-logo-text {
        font-size: 14px;
        font-weight: 500;
        // color: $main;
      }

      .airline-code {
        font-size: 11px;
        font-weight: 300;
        // color: $main;
      }
    }
  }

  &--no-border {
    border-top: 0 !important;
  }
}
