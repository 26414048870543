@import '../../variables';

.basket-container {

  background-color: white;
  padding: 0 1rem;

  .image-carousel {
    .splide__track {
      border-radius: 10px;
    }
  }

  @media screen and (min-width: 769px) {
    border: 1px solid $secondary-light;
    border-radius: 12px;
    padding: 0;

    .image-carousel {
      .splide__track {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}

.basket-container .keen-slider {
  border-radius: 12px 12px 0 0;
}

.basket-title {
  margin-bottom: 5px;

  @media screen and (min-width: 768px) {
    padding: 0 10px;
  }
}

.basket-divider {
  margin: 20px 0;
  border-bottom: solid 1px $secondary-light;

  @media screen and (min-width: 768px) {
    margin: 20px 10px;
  }
}

.basket-subtitle {
  font-weight: 400;

  @media screen and (min-width: 768px) {
    padding: 0 10px;
  }
}

.basket-container-rating {
  display: flex;
  align-items: center;
  margin-top: 5px;

  > div {
    display: flex;
    gap: 10px;
  }
}

.basket-container-rating .rating {
  display: inline;

  @media screen and (min-width: 768px) {
    margin-left: 10px;
  }
}

.basket-rating-trip-logo {
  margin-left: 10px;
}

.fullrate {
  height: 14px;
}

.basket-container-rating-reviews {
  font-size: 10px;
  color: $secondary;
  display: inline;
}

.basket-container-generic-text {
  font-size: 14px;
  margin-top: 3px;

  @media screen and (min-width: 768px) {
    margin: 0 10px;
  }
}

.basket-container-generic-lastchild {
  margin-bottom: 10px;
}

.basket-container-date-formatted {
  margin-top: 15px;
}

.basket-inbound-flights {
  @media screen and (min-width: 768px) {
    margin: 0 10px;
  }
}

.basket-show-more-btn {
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}
.basket-show-more-btn:hover {
  background-color: $button-default;
  color: $white;
}

.basket-show-more {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-weight: bold;
  color: $main;
}

.basket-standout-price-container {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    margin-bottom: 14px;
    margin-left: 10px;
  }
}

.basket-standout-price {
  margin-right: 10px;
  font-weight: normal;
  &-large {
    font-size: 20px;
    font-weight: bold;
  }

  &-xl {
    font-size: 32px;
    font-weight: bold;
  }
}

.basket-container-center {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.basket-standout-price-title {
  font-weight: bold;
  font-weight: 14px;
  &-large {
    font-size: 20px;
  }
}

.basket-standout-price-title {
  font-weight: bold;
  font-weight: 14px;
  &-large {
    font-size: 20px;
  }
}

.basket-standout-price-subtitle {
  font-weight: normal;
  font-weight: 14px;
}

/**
 * Extras and Supplements
 */

.basket-extras {
  border-bottom: solid 1px $secondary-light;
  border-top: solid 1px $secondary-light;
  padding: 20px 0;
  margin: 20px 0;

  @media screen and (min-width: 768px) {
    margin: 20px 10px;
  }
}

.basket-supplement {
  // color: $main;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 12px 0;

  .basket-supplement__price {
    color: $primary;
  }
}

.basket-payment-options {
  margin: 2rem 0 1rem;

  @media screen and (min-width: 768px) {
    margin: 2rem 10px 0;
  }
}

.basket-discounts {
  margin: 20px 10px;
  font-size: 14px;
  font-weight: 700;
  // color: $main;
  border-bottom: 1px solid #cddae5;

  .basket-discount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }

  .basket-discount__code {
    color: $secondary;
  }

  .basket-discount__remove {
    color: #f7a901;
    cursor: pointer;
    font-weight: 400;
    text-decoration: underline;
  }

  .basket-discount__amount {
    color: #f7a901;
  }

  .basket-discount__label {
    line-height: 1.5;
    margin: 0;
  }
}

.basket-rooms {
  p {
    margin: 0;
  }

  .basket-rooms__room {
    border-top: 1px solid #cddae5;
    font-size: 12px;
    margin-top: 1rem;
    padding-top: 14px;
  }

  .basket-rooms__room-title {
    display: block;
    margin-bottom: 0.35rem;
  }

  .basket-rooms__room:last-of-type {
    padding-bottom: 0;
  }
}




  .basket-extras_package_extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: 300;
    font-size: 14px;

    .basket-extras_package_extra-item {
      flex: 1 1 0%;
      margin-left: 1rem;
      // color: #01447b;
      // width: 75%;

      // &:first-of-type {
      //   width: 25%;
      // }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }


