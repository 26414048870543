@import '../../variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
  z-index: 9999;

  .modal-window {
    background-color: $white;
    height: 100%;

    .modal-mobile-header-text {
      color: $main;
      margin: 0;
    }

    .modal-layout {
      display: flex;
      flex-direction: column;
      height: 100%;

      .modal-layout__content {
        overflow: auto;
        height: calc(100% - 80px);
        padding-bottom: 0.5rem;
      }

      .modal-layout__content--map {
        height: 100vh;
        padding-bottom: 0;
      }

      .hwt-modal-header {
        padding: 1rem 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .modal-close {
          height: 24px;
          width: 24px;
          border-radius: 24px;
          border: 1px solid $main;
          cursor: pointer;
          // background-image: url(../assets/close-icon.svg);
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: center;
          z-index: 3;
        }

        .modal-additional-action {
          height: 24px;
          width: 24px;
        }
      }
    }

    .hwt-modal-footer {
      .modal-mobile-footer {
        display: flex;
        gap: 12px;
        padding: 12px;
        background: white;
        z-index: 500;
      }
    }
  }

  @media screen and (min-width: 768px) {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-window {
      display: flex;
      justify-content: center;
      background-color: $white;
      width: 90%;
      height: 75%;
      border-radius: 12px;

      @media screen and (min-width: 1200px) {
        width: 70%;
      }

      .modal-layout {
        display: block;
        flex-direction: unset;
        width: 100%;
        position: relative;

        .modal-layout__content {
          height: 100%;
          padding-bottom: 0;
          position: relative;
        }

        .modal-content {
          height: 100%;
        }

        .hwt-modal-footer,
        .hwt-modal-header {
          display: none;
        }

        .modal-close.modal-close--mobile {
          position: absolute;
          cursor: pointer;
          top: -10px;
          right: -10px;
          height: 24px;
          width: 24px;
          left: unset;
          background-color: $main;
          background-image: url(../assets/close-icon-white.svg);
          z-index: 2;
          border-radius: 50%;
        }
      }
    }
  }
}
