@import '../../variables';

.passengers-details-container {
  .lead-passenger-information,
  .additional-passenger-information {
    max-width: 900px;
    padding: 24px 16px;
    background-color: $white;
    border: 1px solid $secondary-light;
    border-radius: 12px;

    &--no-shadow {
      box-shadow: none;
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
  }

  .additional-passenger-information {
    .additional-passenger-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      border-top: 2px solid #cddae5;
      padding-top: 1.5rem;

      &--no-border {
        border-top: none;
        padding-top: 0;
      }

      .additional-passenger-title-text {
        // color: $main;
        font-weight: bold;
        margin-left: 8px;
      }

      &.additional-passenger-title--room {
        margin-top: 0;
        margin-bottom: 1rem;

        .additional-passenger-title-text {
          margin-left: 0;
        }
      }
    }
  }

  .lead-passenger-information {
    margin-bottom: 32px;

    // Easier to call max-width here instead of refactor
    @media screen and (max-width: 768px) {
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin-bottom: 0;
    }
  }

  .additional-passenger-information {
    .additional-passenger-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .additional-passenger-title-text {
        // color: $main;
        font-weight: bold;
        margin-left: 8px;
      }
    }
    .passenger-attention {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .passenger-attention-text {
        font-size: 14px;
        margin-right: 8px;
      }
    }
    .phone-wrapper {
      display: none;
    }
  }
}

@media (min-width: $screen-small) {
  .additional-passenger-information {
    margin: 24px 0;
    .passenger-dob-phone {
      .dob-wrapper {
        margin-top: 16px;
        width: 50%;
      }
    }
  }
}
