@import '../../variables';

.Menu--fullscreen-popover {
  height: 100vh;
  max-width: 1400px;
  background-color: #053268;
  > div {
    text-align: left;
  }
  margin-left: auto;
  margin-right: auto;
}
.Menu--container {
  background-color: #053268;
  padding-top: 50px;
}

.Menu--Item-Container {
  cursor: pointer;
}

.Menu--Item {
  position: relative;
  display: block;
  z-index: 10;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  cursor: pointer;
  &-white {
    color: $white;
  }
  &-orange {
    color: $primary;
  }
  &-no-icon {
    padding-top: 25px;
  }
  &-with-icon {
    padding-top: 12px;
  }
  &-child-open {
    background-color: $white;
    color: $main;
    margin-top: 0px;
    > .Menu--Item-title {
      font-weight: bold;
    }
    > .Menu--Item-divider {
      top: 0px;
    }
  }
}

.Menu--Item-icon {
  position: relative;
  top: 6px;
  margin-right: 10px;
}

.Menu--Item-chevron {
  position: relative;
  left: 10px;
  top: 5px;
  &-child-open {
    rotate: 180deg;
  }
}

.Menu--Item-divider {
  top: 10px;
  position: relative;
  background-color: $grey-light;
  height: 1px;
  width: 100%;
  padding-right: 25px;
  opacity: 0.5;
}

.Menu--Item-Container > .Menu--Item:first-child {
  margin-top: 0px;
}

.Close--Icon--Popover {
  position: absolute;
  right: 15%;
  top: 25px;
  border: 1px solid $white;
  padding: 5px 15px;
  border-radius: 30px;
  cursor: pointer;
  z-index: 11;
}
