.progress-bar-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 0.5rem;
}

.progress-bar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }
}

.progress-bar-wrapper--small {
  font-size: 10px;
  font-weight: 300;
}

.progress-bar-wrapper--medium {
  font-size: 12px;
  font-weight: 500;
}

.progress-bar-wrapper--large {
  font-size: 14px;
  font-weight: 500;
}

.progress-bar--item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

@media (max-width: 767px) {
  .progress-bar--item {
    font-size: 12px;
  }
}

/* .progress-bar--item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid #cddae5;
  width: 100%;
  top: 15px;
  left: -50%;
  z-index: 2;
} */

/* .progress-bar--item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #cddae5;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 2;
} */

.progress-bar--item .progress-bar--item-counter {
  position: relative;
  color: white;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #cddae5;
  margin-bottom: 6px;
}

.progress-bar--item .active {
  font-weight: bold;
}

/* .active .progress-bar--item-counter {
  background-color: #01447b;
}

.progress-bar--item.completed .progress-bar--item-counter {

} */

/* .progress-bar--item.completed::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #01447b;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 3;
} */

.progress-bar--item:first-child::before {
  content: none;
}

.progress-bar--item:last-child::after {
  content: none;
}

.progress-bar--item-name {
  text-align: center;
}
