@import '../../variables';

.extras-page-container {
  max-width: 1400px;
  display: grid;
  grid-template-columns: 70% 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.extras-page-divider {
  height: 20px;
}

.extras-page-col-basket {
  margin-left: 10px;
}

.extras-page-col-basket-mobile {
  display: none;
}

.extras-page-continue-mobile {
  display: none;
}

.loader-page-fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

@media (max-width: $screen-medium) {
  .extras-page-container {
    grid-template-columns: 100%;

    .basket-container {
      padding: 0 1rem !important;
    }
  }

  .extras-page-continue-mobile {
    display: block;
    padding: 10px 25px 25px;
  }

  .extras-page-col-basket {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    display: none;
  }

  .extras-page-col-basket-mobile {
    display: inline-block;
    border-bottom: 1px solid #cddae5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .basket-container {
    border: none !important;
  }
}

.stepper-loader-button {
  padding-top:5px;
}
