@import '../../variables';

.image-carousel {
  position: relative;

  .splide__arrow {
    background: $white;
  }

  .navigation-wrapper {
    overflow: hidden;
    border-radius: 6px;

    .keen-slider {
      .keen-slider__slide {
        object-fit: cover;
        aspect-ratio: 3/2;
      }
    }

    @media (min-width: $screen-small) {
      border-radius: unset;
    }
  }

  .dots {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: $screen-small) {
      display: flex;
    }

    .dot {
      padding: 5px;
      background-color: $white;
      border: unset;
      margin-right: 8px;
      border-radius: 50%;

      @media (min-width: $screen-small) {
        padding: 6px;
        border: 2px solid $white;
        background-color: transparent;
        margin-right: 10px;
      }

      &.active {
        padding: 3px;

        @media (min-width: $screen-small) {
          padding: 6px;
          background-color: $white;
        }
      }
    }
  }
}
