@import './src/variables';

.duration-select-desktop-view {
  display: block;
  font-weight: 300;
}

.duration-select-mobile-view {
  display: none;
  font-weight: 300;
}

.duration-select-overlay {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

@media (max-width: $screen-small) {
  .duration-select-mobile-view {
    display: block;
  }
  .duration-select-desktop-view {
    display: none;
  }
  .duration-container {
    position: absolute;
    border: 1px solid $secondary;
    top: 78px;
    right: 0;
    width: 480px;
    height: auto;
  }
}
