@use '../../variables' as *;

.react-tabs {
  &__tab-list {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    list-style: none;
    border-bottom: solid 1px $secondary-light;
    margin: 0 10px;
    padding: 0;

    @media screen and (min-width: 768px) {
      margin: 0 20px;
    }
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $main;
    padding: 15px 25px;
    opacity: 0.4;
    cursor: pointer;

    img {
      width: 18px;
      height: 21px;
      margin-bottom: 8px;
    }
  }

  &__tab + &__tab {
    margin-left: 25px;
  }

  &__tab--selected {
    position: relative;
    color: $main;
    opacity: 1;
    outline: none;
  }

  &__tab--selected:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 4px;
    width: 100%;
    background-color: $main;
    border-radius: 2px;
  }
}
.holiday-page-layout {
  max-width: 1440px;
  margin: 0 auto;

  .holiday-page-wrapper {
    display: grid;
    grid-template-columns: 350px 1fr 350px;
    margin: 50px 10px 30px;
    gap: 20px;

    @media (max-width: $screen-medium) {
      grid-template-columns: 1fr;
      margin-top: 24px;

      .search-bar-container {
        padding: 0;
      }
    }

    .card-section {
      width: 100%;
    }

    .main-wrapper {
      .component-wrapper {
        .holiday-price-datepicker {
          @media screen and (min-width: 768px) {
            min-width: 400px;
          }
        }

        &--title {
          margin-bottom: 14px;
          font-size: 14px;
          font-weight: bold;
          color: $main;
        }

        &--component {
          .collapsed-search-options {
            display: grid;
            grid-template-columns: 1fr 1fr;

            & > div {
              width: 100%;
              min-width: 170px;
            }

            @media (max-width: $screen-small) {
              display: grid;
              grid-template-rows: repeat(3, 1fr);
              grid-template-columns: 1fr 1fr 0%;

              & > div {
                width: 100%;
                min-width: 100px;
                grid-column: span 2;
              }

              .room-select-mobile-view {
                grid-column: 3;
                width: 0px;
                min-width: 0px;
              }

              .duration-select-mobile-view {
                grid-column: 3;
                width: 0px;
                min-width: 0px;
              }
              .airport-select-mobile-view {
                width: 0%;
                min-width: 0%;
                grid-column: 3;
              }

              .board-select-mobile-view {
                width: 0%;
                min-width: 0%;
                grid-column: 3;
              }

              .search-bar-input-field-autocomplete:nth-child(1) {
                grid-column: span 2;
              }

              .search-bar-input-field-autocomplete:nth-child(2) {
                grid-column: span 2;
              }

              .icon-wrapper {
                min-width: 50px;
                width: 50px;
              }
            }
          }

          .holiday-price-datepicker {
            border: solid 1px $main;
            border-radius: 5px;
            padding: 20px 0;
          }

          .hwt-toggle {
            margin: 24px auto;
            width: 90%;

            @media screen and (min-width: 768px) {
              margin: 50px auto 30px;
              width: 100%;
            }
          }

          .info-section {
            color: $main;
            margin: 0 auto 10px;
            text-align: center;
            font-size: 14px;

            @media screen and (min-width: 768px) {
              font-size: 1rem;
            }

            .cheapest {
              position: relative;
            }

            .cheapest:before {
              position: absolute;
              content: '';
              left: -10px;
              bottom: 50%;
              background-color: #f7a901;
              border-radius: 50%;
              width: 5px;
              height: 5px;
              transform: translate(0, 90%);
            }

            .selected {
              position: relative;
              margin-left: 50px;
            }

            .selected:before {
              position: absolute;
              content: '';
              left: -20px;
              bottom: 0;
              background-color: $main;
              border-radius: 5px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .summary-section {
      margin-top: 12px;

      @media screen and (min-width: 768px) {
        margin-top: 50px;
      }

      .search-results-container {
        display: flex;
        gap: 1rem;
      }

      .holiday-card-container {
        margin: 0 auto;

        .holiday-card-wrapper {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.react-calendar__navigation__arrow {
  visibility: hidden;
}

.alert {
  padding: 5px;
  margin: 10px;
}

.refine-con .input-field-wrapper {
  background-color: #fff;
}