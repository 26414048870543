@use './src/variables';

.hwt-toggle {
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: variables.$secondary-light;
  color: variables.$main;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  width: 320px;
  display: flex;
  justify-content: center;
  align-content: center;
  white-space: nowrap;

  span {
    padding: 5px 15px;
    width: 50%;
    text-align: center;
  }

  span + span {
    margin-left: 5px;
  }

  &--small {
    width: auto;
    font-size: 12px;
  }

  &--small {
    span {
      padding: 5px;
    }
  }

  &--medium {
    font-size: 12px;

    span {
      padding: 5px 10px;
    }
  }

  &--large {
    font-size: 12px;
    max-width: 300px;
    width: unset;
  }

  .selected {
    color: white;
    background-color: variables.$main;
    border-radius: 20px;
    transition: background-color 200ms;
  }

  span {
    padding: 5px 13px;
    width: 300px;
    text-align: center;
  }

  & > span + span {
    margin-left: 5px;
  }
}
