@import '../../variables';

.passenger-information-container {
  .dob-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .dob-input {
      width: 33%;
    }
    .error-message {
      position: absolute;
      top: 65px;
      left: 0;
      font-size: 12px;
      font-weight: 100;
      color: red;
    }
  }
  .passenger-input-wrapper {
    margin: 8px 0 20px 0;
    position: relative;

    .passenger-info-input {
      width: 100%;
      font-size: 16px;
      border: 1px solid $secondary;
      border-radius: 4px;
      min-height: 38px;
      padding: 2px 8px;
      box-sizing: border-box;

      &:focus {
        outline: none !important;
      }
    }
    .error-message {
      display: none;
      position: absolute;
      bottom: -16px;
      left: 0;
      font-size: 12px;
      font-weight: 100;
      color: red;
    }
  }
  .select-label,
  .dob-inputs-title {
    font-weight: bold;
  }
}
.passengers-details-container,
.passenger-dob-phone {
  position: relative;

  &.invalid {
    input {
      &:invalid {
        border: 1px solid red !important;

        & ~ .error-message {
          display: block;
        }
      }
    }
  }
}

@media (min-width: $screen-small) {
  .passenger-main-info-desktop {
    display: flex;
    gap: 12px;

    .passenger-title {
      width: 20%;
    }

    .passenger-first-name {
      width: 30%;
    }

    .passenger-last-name {
      width: 50%;
    }
  }

  .passenger-dob-phone {
    gap: 12px;
    display: flex;
    align-items: end;
    width: 100%;

    .dob-inputs-title {
      position: absolute;
      top: 0;
    }

    .dob-wrapper {
      width: calc(50% + 12px);
    }
    .phone-wrapper {
      width: 50%;
    }
  }
}
