@import '../../variables';

.pagination-circle {
  height: 40px;
  width: 40px;
  border-radius: 60px;
  border: 1px solid $grey-light;
  color: $main;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pagination-circle.pagination-selected {
  background-color: $main;
  color: $white;
}

.pagination-right-arrow,
.pagination-left-arrow {
  position: relative;
  top: 1px;
}

.pagination-left-arrow {
  rotate: 90deg;
  left: -1px;
}
.pagination-right-arrow {
  rotate: -90deg;
  left: 1px;
}

.pagination-flex-container {
  display: inline-flex;
}
