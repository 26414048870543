@import '../../variables';

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-ring {
    background: $main;
    border-radius: 50%;

    div {
      border-color: #fff transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 14.5px;
    }
  }
}
