@use './src/variables' as *;

.select-flights-align-center {
  text-align: center;
  width: 100%;
  margin-top: 16px;
}

.flight-button {
  font-weight: 700;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0px;
  height: 35px;
  width: 130px;
  border-radius: 20px;
  color: $secondary-light;
  background-color: transparent;
  border: 1px solid $secondary-light;

  // @media screen and (min-width: 768px) {
  //   position: absolute;
  //   top: 20px;
  //   right: 15px;
  // }

  &-active {
    border-color: $primary;
    color: $primary;
  }
}

.flight-button:hover {
  background-color: $primary;
  color: $white;
}

.select-flights-wrapper {
  // Easier to set a max-width query than to unset
  // all the properties.
  @media screen and (max-width: 768px) {
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
    padding: 1rem;
    border-radius: 10px;
  }

  &--header {
    display: flex;
    // color: $main;
    margin-bottom: 25px;

    img {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }

    span {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &--body {
    .select-flights-list {
      &--item {
        padding-bottom: 15px;
        border: solid 1px $secondary-light;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr;

        @media screen and (min-width: 768px) {
          grid-template-columns: 2fr 1fr;
        }

        .airline-item-wrapper {
          .flight-details-wrapper {
            padding: 20px 10px;

            @media screen and (min-width: 768px) {
              border-right: solid 1px $secondary-light;
            }

            .flight-details--top-section {
              .flight-details--main-section {
                order: 2;

                .route-point {
                  padding: 20px 1em 0 1em;
                }
              }

              .flight-info {
                text-align: right;
              }

              .flight-info .airline-logo-text img {
                width: 120px;
              }

              @media (min-width: $screen-small) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .flight-details--main-section {
                  order: inherit;
                  .route-point {
                    padding: 0;
                  }
                }
              }
            }

            @media (min-width: $screen-small) {
              border-right: none;
            }
          }
        }

        .airline-item-wrapper + .airline-item-wrapper {
          .flight-details-wrapper {
            border-top: solid 1px $secondary-light;
          }
        }

        .airline-order-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 10px;

          @media (min-width: $screen-small) {
            flex-direction: row;
            justify-content: space-around;
          }

          .airline-order-section--save {
            display: flex;
            flex-direction: column;
            text-align: right;
            font-size: 18px;
            font-weight: bold;
            color: $primary;

            .airline-order-section__price {
              margin: 12px 0;
              text-align: center;
            }

            @media screen and (min-width: 768px) {
              .airline-order-section__price {
                margin: 0;
              }
            }

            @media (min-width: $screen-small) {
              width: 50%;
            }

            .save-label {
              font-size: 14px;
              font-weight: 400;
              display: block;
            }
          }

        }

        // .selected-airline {
        //   button {
        //     border-color: $primary;
        //     color: $primary;
        //     background-color: $primary-light;
        //     cursor: pointer;
        //   }
        // }
      }

      .select-flights-list--item + .select-flights-list--item {
        margin-top: 1rem;
        @media screen and (min-width: 768px) {
          margin-top: 10px;
        }
      }

      .selected-airline {
        border-color: $primary;
      }
    }
  }
}

.solid {
  border-top: 1px solid #cddae5;
  margin-left: 1em;
}
