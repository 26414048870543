@import '../../variables';

// .accomodation-container {
//   width: 100%;
//   border: 1px solid $secondary-light;
//   padding-bottom: 10px;
//   color: $main;
//   border-radius: 12px;
// }

.accomodation-container-child {
  padding: 10px 15px;
  // margin: 5px 15px;
  margin-bottom: 1rem;
  position: relative;

  &-active {
    border: 1px solid $primary;
  }

  border: 1px solid $secondary-light;
  border-radius: 4px;
  display: grid;
  text-align: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: 60% 20% 20%;
    text-align: left;
  }
}

.accomodation-list-options {
  display: flex;
}

.accomodation-list-options > div {
  font-size: 10px;
}
.accomodation-list-options > div > img {
  position: relative;
  top: 5px;
  margin-left: 3px;
}

.accomodation-title {
  font-size: 14px;
  margin-top: 10px;
}

.accomodation-container-room {
  font-size: 14px;
  margin-left: 5px;
}
.accomodation-title-text {
  margin-left: 10px;
}

.accomodation-icon {
  position: relative;
  top: 5px;

  @media screen and (min-width: 768px) {
    margin-left: 25px;
  }
}

.accomodation-container-duration {
  margin-top: 24px;
  font-size: 14px;
}

.accomodation-container-price {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: $primary;
  text-align: center;
  width: 100%;

  .accomodation-container-price__value {
    display: block;
    margin: 12px 0;
    text-align: center;

    @media screen and (min-width: 768px) {
      display: inline-block;
      margin: 0;
    }
  }

  @media screen and (min-width: 768px) {
    margin-right: 20px;
    text-align: right;
  }
}

.accomodation-container-per-person {
  font-size: 14px;
  font-weight: lighter;
}

.accomodation-description {
  font-size: 14px;
  font-weight: normal;
  margin-left: 25px;
}

.accomodation-container-title {
  margin: 5px;
}

.accomodation-description-terms {
  font-size: 12px;
  font-style: italic;
  text-decoration: underline;
  font-weight: normal;
  margin-left: 25px;
}
.accomodation-container-mobile {
  display: none;
}
.accomodation-container-child-mobile {
  display: none;
}

.accomodation-button {
  font-weight: 700;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0px;
  height: 35px;
  color: $secondary;
  background-color: transparent;
  border: 1px solid $light;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 130px;
    position: absolute;
    top: 20px;
    right: 15px;
  }

  &-active {
    border-color: $primary;
    color: $primary;
    background-color: $primary-light;
  }
}

.accomodation-container-terms {
  font-style: italic;
  margin-top: 14px;
  margin-left: 5px;
  font-size: 14px;
  text-align: center;
}

.accomodation-button:hover {
  background-color: $primary;
  color: $white;
}

@media (max-width: $screen-medium) {
  .accomodation-container {
    border-radius: 0px;
    margin-top: 2rem;
  }

  .accomodation-container {
    display: block;
  }

  // .accomodation-container-child {
  //   display: none;
  // }

  .accomodation-container-mobile {
    display: inline-block;
    width: 100%;
    border: 1px solid $secondary-light;
    padding-bottom: 10px;
    color: $main;
  }

  .accomodation-container-child-mobile {
    width: calc(100% - 30px);
    padding: 10px 15px;
    margin: 5px 15px;
    position: relative;
    border: 1px solid $secondary-light;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 70% 30%;
    &-active {
      border: 1px solid $primary;
    }
  }
  .accomodation-container-price {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 0px;

    @media screen and (min-width: 768px) {
      margin-bottom: 50px;
      right: 20px;
      position: relative;
    }
  }

  // .accomodation-button {
  //   top: 75px;
  // }
  .accomodation-container-terms {
    font-style: italic;
    margin-top: 14px;
    margin-left: 5px;
    font-size: 14px;
    text-align: left;
  }
}
