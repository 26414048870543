@import '../../variables';

.MenuChild-Grid {
  display: grid;
  color: $main;
  font-weight: light;
  padding: 15px 10px;
  margin-left: 5px;
  cursor: auto;
  > span {
    display: inline-block;
    width: fit-content;
    height: 23px;
  }
}

.MenuChild-Grid > span:hover {
  color: $white;
  background-color: $main;
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  .MenuChild--Item-chevron {
    filter: brightness(0) invert(1);
  }
}

.MenuChild-Grid-Active {
  background-color: $main;
  > span {
    color: $white;
    padding-left: 10px;
    padding-right: 10px;
  }
  .MenuChild--Item-chevron {
    filter: brightness(0) invert(1);
    rotate: 180deg;
  }
}

.MenuChild-Grid-Children {
  display: grid;
  color: $main;
  font-weight: light;
  margin-left: 5px;
  cursor: auto;
  > span {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 50px;
  }
  > span:hover {
    color: $white;
    background-color: $main;
    display: inline-block;
    position: relative;
    width: 100%;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
  }
}

.MenuChild-Container {
  display: Grid;
  background-color: $white;
  grid-template-columns: repeat(4, 1fr);
}

.MenuChild-Container-SubChild {
  display: Grid;
  background-color: $white;
  background-color: $grey-light;
  grid-template-columns: repeat(3, 1fr);
  .MenuChild-Container-SubChild-Title {
    cursor: pointer;
    font-weight: bold;
    color: $main;
    position: relative;
    display: inline-block;
    padding: 10px 15px;
  }
}

.MenuChild-Container-SubChild-Col {
  display: Grid;
  grid-template-columns: repeat(1, 1fr);
  padding-left: 15px;
  color: $main;
  font-size: 14px;
  padding-top: 0px;
  > span {
    position: relative;
    margin-top: 2px;
    cursor: pointer;
  }
}

.MenuChild-Container-SubChild-Col:last-child {
  margin-bottom: 25px;
}

.MenuChild--Item-chevron {
  position: absolute;
  right: 10px;
  top: 20px;
}

@media (max-width: 575px) {
  .MenuChild-Container {
    background-color: $white;
    grid-template-columns: repeat(1, 1fr);
  }
  .MenuChild-Container-SubChild {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 5px;
  }
  .MenuChild-Grid-Mobile {
    height: 50px;
  }
  .MenuChild-Grid {
    display: Grid;
    grid-template-columns: repeat(1, 1fr);
    color: $main;
    font-size: 14px;
    padding-top: 0px;
    padding-right: 25px;
    > span {
      position: relative;
      margin-top: 2px;
      width: 100%;
      cursor: pointer;
      height: 50px;
      padding-top: 15px;
    }
    > span:hover {
      color: $white;
      background-color: $main;
      display: inline-block;
      position: relative;
      width: 100%;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
      height: 50px;
      border-radius: 0px;
    }
  }
  .MenuChild-Grid-Active {
    > span:hover {
      background-color: transparent;
    }
  }
}
