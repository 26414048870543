@use '../../../variables' as *;
.success-icon-wrapper {
  text-align: center;
  .success-icon-img {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    border: solid 3px $main;
    padding: 17px;

    img {
      content: ' ';
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p {
    font-size: 20px;
    font-weight: bold;
    margin: 5px 0 0 0;
    // color: $main;
  }
}
