@use './src/variables';

.filters-wrapper-desktop {
  display: inline-block;
  border-radius: 12px;

  .filter-section {
    padding: 30px 40px;
    background: white;
    margin-bottom: 15px;
    border-radius: .5rem;
  }

  .filter-section + .filter-section {
    border-top: 1px solid variables.$light;
  }
}

.filters-wrapper-mobile {
  display: block;
}

.filter-sidebar-button {
  display: none;
}

@media (max-width: variables.$screen-medium) {
  .filters-wrapper-desktop {
    display: none;
  }

  .filters-wrapper-mobile {
    // display: block;
    // border: solid 1px variables.$light;
    // border-radius: 5px;
    // height: 100%;
    // overflow-y: scroll;
    // padding-bottom: 20px;
    // position: relative;
    // z-index: 9999;
    display: block;

    .toggle span {
      padding: 5px 10px;
    }

    .filter-section {
      padding: 1rem;
      position: relative;

      .toggle {
        margin: 0 auto;
      }

      &:after {
        content: '';
        width: 90%;
        text-align: center;
        margin: 0 auto;
        height: 1px;
        background: variables.$secondary-light;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    .filter-section + .filter-section {
    }
  }

  .modal-content {
    height: calc(100% - 56px);
    overflow-y: auto;
    padding-bottom: 1.5rem;
    box-shadow: 0px -4px 14px -3px rgba(50, 50, 50, 0.35);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .modal-footer-wraper {
    display: flex;
    gap: 10px;
    padding: 0 1rem;
  }

  .filter-sidebar-button {
    cursor: pointer;
    // background-color: variables.$main;
    color: variables.$white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    position: fixed;
    z-index: 9000;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.75rem 2rem;
    border-radius: 24px;

    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0.75rem;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
