@use '../../variables' as *;

.calendar-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .react-calendar {
    width: 100%;
    font-family: $base-font;

    &__tile {
      &--rangeStart {
        background-color: $main !important;
        color: $white !important;
        border-radius: 5px;
      }
    }

    &__navigation {
      display: flex;
      justify-content: space-between;
      text-align: center;

      &__prev2-button {
        display: none;
      }

      &__prev-button {
        // color: $main;
        border: none;
        background-color: white;
        font-size: 32px;
        margin-left: 6%;
        cursor: pointer;
      }
      &__label {
        flex-grow: inherit !important;
      }
      &__next-button {
        // color: $main;
        border: none;
        background-color: white;
        font-size: 32px;
        margin-right: 6%;
        cursor: pointer;
      }
      &__next2-button {
        display: none;
      }
    }

    &__viewContainer {
      .react-calendar__month-view {
        div {
          .react-calendar__month-view__weekdays {
            border-bottom: solid 1px $main;
            margin-bottom: 10px;
            font-size: 14px;

            @media screen and (min-width: 768px) {
              font-size: 16px;
            }

            &__weekday {
              padding: 3px;
              margin: 15px 0 10px;
              text-align: center;
              color: #01447b;
            }

            abbr {
              text-decoration: none;
            }
          }
          .react-calendar__month-view__days {
            &__day.selected-date {
              background-color: $main;
              color: $white;
            }

            &__day {
              border: none;
              background-color: $white;
              color: $main;
              cursor: pointer;
              padding: 2px 5px;
              margin: 3px;
              text-align: center;
              font-family: $base-font;
              line-height: 1;
              margin: 0 !important;

              @media (min-width: $screen-small) {
                padding: 10px;
                text-align: center;
              }

              &--now {
                background-color: $secondary-light;
                border-radius: 5px;
              }

              &:hover {
                background-color: $secondary-light;
                border-radius: 5px;
              }
            }

            abbr {
              display: block;
              margin-bottom: 3px;
              font-weight: 400;
            }

            .selected-range {
              // background-color: $main;
              border-radius: 5px;
              color: white;
            }

            .low-price {
              color: $yellow;
              position: relative;
            }

            .low-price:after {
              position: absolute;
              content: '';
              top: 17px;
              left: 50%;
              background-color: $yellow;
              border-radius: 50%;
              width: 5px;
              height: 5px;
              transform: translate(-50%, 0);
            }
          }
        }
      }
    }
  }

  .hwt-custom-select--title {
    position: relative;
  }

  .hwt-custom-select--title img {
    rotate: 90deg;
  }

  .hwt-custom-select {
    position: absolute;
    top: -5px;
    width: 100%;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
      width: 2em;
      cursor: pointer;
    }

    .arrow-prev {
      rotate: 180deg;
    }

    .arrow-next {
      justify-self: end;
    }
  }
}

@media (max-width: $screen-small) {
  .calendar-wrapper {
    position: relative;
    .react-calendar {
      &__viewContainer {
        .react-calendar__month-view {
          div {
            .react-calendar__month-view__days {
              &__day {
                padding: 5px;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
