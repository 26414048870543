@import '../../variables';

.tripadvisor-votes-amount-wrapper {
  display: flex;
  align-items: center;

  .tripadvisor-votes-amount-indicator {
    height: 16px;
    background-color: $green;
    border-radius: 16px;
  }
  .tripadvisor-votes-amount {
    font-size: 14px;
    color: $grey;
    margin-left: 8px;
  }
}

@media (min-width: $screen-medium) {
  .tripadvisor-votes-amount-wrapper {
    .tripadvisor-votes-amount-indicator {
      height: 20px;
      border-radius: 20px;
    }
    .tripadvisor-votes-amount {
      font-size: 16px;
      margin-left: 12px;
    }
  }
}
