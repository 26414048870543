@import '../../variables';

.select-page-container {
  max-width: 1400px;
  display: grid;
  grid-template-columns: 70% 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.select-page-divider {
  height: 20px;
}

.select-page-container-select-flights {
  background-color: white;
  border-radius: 12px;
  padding: 1rem 0;

  @media screen and (min-width: 769px) {
    border: 1px solid $secondary-light;
    padding: 20px;
  }
}

.loader-page-container {
  text-align: center;
  height: 100%;
  background-color: $white;
  position: absolute;
  display: inline-block;
  width: 100%;
}

.loader-page-logo {
  position: relative;
  top: 190px;
  margin-bottom: 2.5em;
}

.loader-page-fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.select-page-col-basket-mobile {
  display: none;
}
.select-page-continue-mobile {
  display: none;
}


// .select-page-board-details {
//   color: $main;
// }

.board-container {
  width: 100%;
  border: 1px solid $secondary-light;
  padding-bottom: 20px;
  // color: $main;
  border-radius: 12px;
  margin-bottom: 20px;
}

.board-title {
  font-size: 14px;
  margin: 0 0 20px 0;
}

.board-title-text {
  margin-left: 10px;
}

.board-icon {
  position: relative;
  top: 5px;

  @media screen and (min-width: 768px) {
    margin-left: 5px;
  }
}

.board-and-rooms-container {
  width: 100%;
  background-color: white;
  // color: $main;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 10px;

  @media screen and (min-width: 769px) {
    box-shadow: none;
    border: 1px solid #cddae5;
    padding: 20px;
  }
}

@media (max-width: $screen-medium) {
  .select-page-container {
    grid-template-columns: 100%;

    .basket-container {
      padding: 0 1rem !important;
    }
  }

  .select-page-container-select-flights {
    // border-radius: 0px;
    padding-top: 1.5rem;
    margin-top: 1rem;
  }

  .select-page-col-basket-mobile {
    display: inline-block;
    border-bottom: 1px solid $secondary-light;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 5px 0px rgb(0 0 0 / 25%);
  }

  .select-page-continue-mobile {
    display: block;
    padding: 10px 25px 25px;
  }
}

@media (max-width: $screen-small) {
  .loader-page-logo {
    position: relative;
    top: 10px;
    height: 50px;
  }
}
