@import './src/variables';


.destsel-desktop-view{
  position: absolute;
}

.destsel-mobile-view{
  display: none;
}

.destsel-container {

    position: relative;
    background-color: white;
    z-index: 11;
    left: 0;
    padding: 16px;
    border-radius: 4px;
    width: 400px;
    height: 100%;
    right: 0;
    overflow: auto;
    .button {
      margin-bottom: 16px;
    }
    margin-top: 16px;
  }

  .destsel-container {

    border: 1px solid black;

    @media screen and (min-width: 1200px) {
      width: 300px;
      max-width: 300px;
    }
  }

  .datesel-input {
    outline-width: 0;
    width: 100%;
    border-width: 1px;
    border-radius:6px;
    cursor: default;
    border-color: #99b4ca;
    padding: 5px 5px 5px 5px;
    font-size: 17px;

  }

  @media (max-width: 768px) {
      .destsel-mobile-view{
        display: block ;
      }

      .destsel-desktop-view{
        display: none;
      }

      .datesel-mobile-con {
        position: fixed;
        top: 0;
        height: 100dvh;
        width: 100%;
        background: white;
        z-index: 999999999;
        left: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
  
      .destsel-container {
        background-color: white;
        z-index: 11;
        width: 100%;
        height: calc(100% - 140px);
        border: none;
        box-shadow: 0px -4px 14px -3px rgba(0, 0, 0, 0.25);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

      }

      .dest-header-section {
        height: 60px;
        color : #01447b;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .dest-header-section-text  {
        margin: 0;
      }
      
      .dest-footer-section {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        padding: 1rem;
      }
      
      .dest-footer-section button {
        height: 100%;
      }
      
      .dest-header-section-close  {
        height: 24px;
        width: 24px;
        border-radius: 24px;
        border: 1px solid $main;
        cursor: pointer;
        background-image: url(../assets/close-icon.svg);
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 3;
        position: absolute;
        left: 10px;
        top: 18px;
      }
}

