@import '../../variables';

.button {
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: solid 1px #01447b;
}

.button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.button--primary {
  color: white;
  background-color: $button-default;
}

.button--secondary {
  color: #01447b;
  background-color: #cedbe6ff;
}

.button--tertiary {
  color: white;
  background-color: #01447b;
}

.button--small {
  font-size: 12px;
  padding: 10px;
}

.button--medium {
  font-size: 14px;
  padding: 12px 10px;
}

.button--large {
  font-size: 16px;
  padding: 12px 24px;
}

.button--search {
  // padding: 20px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

// .button--search::after {
//   content: '';
//   display: inline-block;
//   width: 25px;
//   height: 25px;
//   margin-left: 10px;
 
// }

.button--plus {
  padding: 20px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  // color: #01447b;
}

.button--plus::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 50 50' height='50px' id='Layer_1' version='1.1' viewBox='0 0 50 50' width='50px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect fill='none' height='50' width='50'/%3E%3Cline fill='currentColor' stroke='currentColor' stroke-miterlimit='10' stroke-width='4' x1='9' x2='41' y1='25' y2='25'/%3E%3Cline fill='currentColor' stroke='currentColor' stroke-miterlimit='10' stroke-width='4' x1='25' x2='25' y1='9' y2='41'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.button--transparent {
  padding: 20px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: solid 1px #01447b;
  // color: #01447b;
}

.button--icon {
  display: inline;
  top: 5px;
}
