@use './src/variables';

.month-picker-wrapper {
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;

  &--item {
    .year-wrapper {
      border-top: solid 1px variables.$secondary;
      color: variables.$main;
      margin: 10px 0 20px;

      .year-title {
        text-align: center;
        font-size: 20px;
        margin: 20px;
        font-weight: 500;
      }

      .months {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin: 0 10px;

        span {
          cursor: pointer;
          padding: 15px 0;
          border: solid 1px variables.$secondary;
          border-radius: 5px;
          text-align: center;
        }
      }
    }
  }

  &--large {
    font-size: 16px;
  }

  &--medium {
    font-size: 14px;
  }

  &--item-medium {
    .year-wrapper {
      margin: 10px 0 20px;

      .year-title {
        font-size: 18px;
        margin: 10px;
      }

      .months {
        margin: 0 8px;

        span {
          padding: 8px 0;
        }
      }
    }
  }

  &--small {
    font-size: 12px;
  }

  &--item-small {
    .year-wrapper {
      margin: 10px 0 20px;

      .year-title {
        font-size: 16px;
        margin: 8px;
      }

      .months {
        margin: 0 6px;

        span {
          padding: 6px 0;
        }
      }
    }
  }
}
