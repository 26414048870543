@import '../../variables';

.loader-container {
  width: 700px;
  border: 1px solid $secondary;
  border-radius: 12px;
  text-align: center;
  // color: $main;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 200px;
}

.loader-icons-container {
  margin-top: 2em;
}

.loader-nogrid {
  grid-template-columns: 33% 33% 33%;
  padding: 20px;
  .loader-ring {
    margin-top: 10px;
  }
}

.loader-grid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 20px;
  .loader-ring {
    margin-top: 10px;
  }
}

.loader-grid > div {
  padding: 20px;
  font-weight: bold;
  font-size: 12px;
  // color: $main;
  text-align: center;
}

.loader-tick {
  border: 4px solid $main;
  border-radius: 120px;
  padding: 10px 12px;
  margin-top: 10px;
  display: inline-block;
}

.loader-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid black;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.loader-grid-auto {
  grid-template-columns: auto;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.abta-atol-logo {
  display: flex;
  justify-content: center;
  gap: 2em;
  margin: 2em 0 3em 0;
}

@media (max-width: $screen-small) {
  .loader-container {
    height: auto;
    width: auto;
    border: none;
    text-align: center;
    // color: $main;
    top: 0px;
  }

  .loader-grid {
    display: grid;
    grid-template-columns: 100%;
    padding: 20px;
    .loader-ring {
      position: relative;
      top: -20px;
      margin-left: 40px;
      height: auto;
    }
    .loader-ring div {
      height: 20px;
      width: 20px;
    }
    .loader-icons {
      width: 50px;
      height: 50px;
      text-align: center;
      position: relative;
    }
    .loader-icons-container {
      text-align: center;
    }
  }
}

.loader-ring-small {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}
.loader-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 2px solid black;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.loader-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader-ring-small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
