@import '../../variables';

.tripadvisor-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .tripadvisor-logo-title {
    font-size: 10px;
  }
  .tripadvisor-logo {
    height: 42px;
  }
}

@media (min-width: $screen-medium) {
  .tripadvisor-logo-wrapper {
    .tripadvisor-logo-title {
      font-size: 12px;
    }
    .tripadvisor-logo {
      height: 54px;
    }
  }
}
