@import '../../variables';

.payment-page-container {
  max-width: 1400px;
  display: grid;
  grid-template-columns: 70% 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;

  @media screen and (min-width: 768px) {
    margin-top: 20px;
  }
}

.payment-page-divider {
  height: 20px;
}

.payment-page-divider-10 {
  height: 10px;
}

.payment-page-col {
  @media screen and (min-width: 768px) {
    padding: 0.5rem;
  }
}

.payment-page-col-basket {
  margin-left: 10px;
}

.payment-page-col-basket-mobile {
  display: none;
}

@media (max-width: $screen-medium) {
  .payment-page-container {
    grid-template-columns: 100%;
  }

  .payment-page-col-basket {
    margin-left: 0px;
    margin-right: 0px;
    // padding-left: 10px;
    // padding-right: 10px;
    padding: 0 1rem;
    margin-bottom: 20px;
    display: none;
  }

  .payment-page-col-basket-mobile {
    display: inline-block;
    // border: 1px solid #cddae5;
    // border-radius: 12px;
    // margin: 0.5rem;
    margin-top: 0;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
  }

  .payment-page-col {
    margin-top: 1rem;
  }

  .basket-container {
    border: none !important;
    padding: 0;
  }
}

.select-payment-passengers {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    text-transform: capitalize;
    margin-bottom: 0.25rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.select-payment-passenger-details {
  padding: 1rem;
  font-size: 14px;

  .select-payment-title {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.select-payment-other-passengers {
  margin-top: 1.5rem;
}
