@import '../../variables';

.select-payment-container {
  background-color: white;
  width: 100%;
  border: 1px solid $secondary-light;
  padding-bottom: 10px;
  // color: $main;
  border-radius: 12px;
}

.select-payment-whitespace {
  height: 30px;
}

.select-payment-container-child {
  width: calc(100% - 30px);
  padding: 10px 15px;
  margin: 10px 15px;
  position: relative;
  border-radius: 12px;
  border: 1px solid $secondary-light;
  display: grid;

  &-active {
    border: 1px solid $primary;
  }

  &--credit-card {
    background-color: #e8edf2;
    border: 1px solid $secondary;
    // color: $main;
    border-radius: 6px;
    margin: 0;
    width: 100%;
    padding: 2rem 0.5rem !important;
    align-items: center;

    @media screen and (min-width: 425px) {
      padding: 2rem 1.25rem !important;
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 3rem;
    }

    .select-payment-breakdown {
      grid-template-columns: 40px 4fr 3fr;

      .select-payment-breakdown-price {
        text-align: right;
        padding-right: 1rem;
      }
    }

    .select-payment-breakdown:last-of-type {
      margin-bottom: 0;
    }
  }

  &--dd-overview {
    margin-bottom: 3rem;
  }

  &--no-border {
    border: 0 !important;
  }
}

.select-payment-title {
  font-size: 14px;
  margin-top: 10px;

  &--important-info {
    display: flex;
    align-items: center;
    margin-top: 12px;

    svg {
      margin-right: 5px;
      margin-left: 15px;
    }
  }
}

.select-payment-icon {
  position: relative;
  top: 5px;
  margin-right: 5px;
  margin-left: 15px;
}

.select-payment-button-container {
  text-align: right;
  display: flex;
  align-items: start;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    padding: 0;
  }
}

.select-payment-button {
  font-weight: 700;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0px;
  height: 35px;
  width: 100%;
  color: $secondary-light;
  background-color: transparent;
  border: 1px solid $secondary-light;

  @media screen and (min-width: 768px) {
    width: 130px;
  }

  &-active {
    border-color: $primary;
    color: $primary;
    background-color: $primary-light;
  }
}

.select-payment-button:hover {
  background-color: $primary;
  color: $white;
}

.select-payment-container-duration {
  margin-top: 24px;
  font-size: 14px;
}

.select-payment-container-price {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  // color: $primary;
  text-align: right;
}

.select-payment-container-per-person {
  font-size: 14px;
  font-weight: lighter;
}

.select-payment-container-child-mobile {
  display: none;
}

.select-payment-breakdown-title,
.select-payment-breakdown-price {
  position: relative;
  top: 5px;
  &-with-date {
    top: 0px;
  }
}

.select-payment-grid-50-50 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.select-payment-breakdown-date {
  font-size: 14px;
  font-weight: normal;
  margin-top: 0.5rem;
}

.select-payment-circle-line {
  height: 85px;
  width: 3px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: $secondary;
  &-active {
    background-color: $main;
  }
}

.select-payment-circle {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  width: 30px;
  background: $secondary;
  border-radius: 120px;
  position: relative;
  z-index: 10;
  &-active {
    background-color: $main;
  }
}

.select-payment-breakdown {
  display: grid;
  margin-bottom: 40px;
  grid-template-columns: 40px 3fr 3fr 2fr;
  gap: 15px;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }

  &-active {
    margin-bottom: 50px;
  }

  &--credit-card {
    margin-bottom: 0;
  }
}

@media (max-width: $screen-medium) {
  .select-payment-grid-50-50 {
    display: grid;
  }

  .select-payment-breakdown {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: 50px 120px auto;
  }

  .payment-page-col {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem 10px;
  }

  .select-payment-container {
    // border: 0;

    .select-payment-container-child {
      display: block;
      width: 100%;
      margin: 0;
      border: 0;
    }
  }

  .select-payment-container-price {
    position: absolute;
    right: 50px;
    top: 5px;
    font-size: 16px;
    margin-top: 0px;
  }
  .select-payment-button {
    &-has-price {
      top: 50px;
    }
  }
  .select-payment-container-duration {
    margin-top: 0px;
    font-size: 14px;
    padding-bottom: 20px;
  }
  .select-payment-heading {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .select-payment-circle-line {
    height: 72px;
  }
}

@media (max-width: $mobile-large) {
  .select-payment-grid-50-50 {
    display: grid;
    grid-template-columns: 1fr;
  }

  .select-payment-container-child {
    width: 100%;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 0px;
    position: relative;
    // border-radius: 0px;
    border: 1px solid #cddae5;
    display: grid;
    margin-bottom: 10px;
    margin-top: 12px;
  }
}

@media (max-width: 620px) {
  .select-payment-grid-50-50 {
    display: grid;
    grid-template-columns: 1fr;
  }
}
