@use '../../variables' as *;

.pak-search-results-page {
  max-width: 1440px;
  margin: 0 auto;

  .search-bar-wrapper {
    max-width: 1440px;
    margin: 42px auto 0px;
    position: relative;

    &--no-index {
      z-index: 0;
    }

    .search-page-main-heading {
      // color: $main;
      font-size: 1.1rem;
      margin-top: 0;
      margin-bottom: 0;
      // padding: 0 10px;

      @media screen and (min-width: 470px) {
        font-size: 1.25rem;
      }

      @media screen and (min-width: 640px) {
        font-size: 1.75rem;
        margin-bottom: 0;
      }

      @media (min-width: 1024px) {
        margin: 0;
        font-size: 40px;
      }
    }

    .search-page-top-wrapper__btn-wrap {
      display: none;

      @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
      }

      .sort-by-wrapper {
        display: none;
      }

      @media screen and (min-width: 1024px) {
        .sort-by-wrapper {
          display: block;
        }

        > div {
          margin-right: 2rem;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .search-results-container {
      margin: 0 10px;
    }

    @media screen and (min-width: 768px) {
      .search-results-container {
        display: flex;
        gap: 1rem;
      }
    }

    .holiday-card-container {
      @media screen and (max-width: 1023px) {
        margin: 0 auto;
      }

      @media screen and (min-width: 1024px) {
        margin-left: auto;
      }

      .holiday-card-wrapper {
        margin-bottom: 1rem;
      }
    }
  }

  .hidden {
    display: none;
  }
}

/**
 * Search Page Top Wrapper
 */

.search-page-top-wrapper {
  display: flex;
  padding: 0 0.5rem;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 42px;
  }

  p {
    margin: 0;
  }
}

/**
 * Package Search Loader
 */

.package-search-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 1rem;
}

.package-search-loader-modal {
  background: white;
  padding: 2rem;
  height: 100%;
  max-height: 450px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  // color: $main;

  &__title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  &__spinner {
    margin: 1rem 0;
  }

  &__logos {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    img:first-of-type {
      margin-right: 2rem;
    }
  }
}
