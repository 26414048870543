/**
 * Voucher Form Styles
 */

.voucher-form-title {
  color: #01447b;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}

.voucher-form {
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  border: none;

  @media screen and (min-width: 425px) {
    padding: 0;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    max-width: 450px;
  }

  .voucher-form__input {
    width: 100%;
    font-size: 16px;
    color: #01447b;
    border: 1px solid #99b4ca;
    border-radius: 4px;
    min-height: 38px;
    padding: 2px 8px;
    box-sizing: border-box;
    margin-right: 2rem;
    margin-bottom: 1rem;
    font-family: inherit;
  }

  .voucher-form__button {
    appearance: none;
    background: #f7a901;
    border: 1px solid transparent;
    color: #fff;
    font-weight: 700;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    height: 35px;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 130px;
    }
  }
}

.voucher-form-success,
.voucher-form-error {
  font-weight: 700;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 1rem;
}

.voucher-form-error {
  color: #cd0303;
}
