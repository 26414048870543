// ScreenSizes

$screen-large: 1280px;
$screen-medium: 1024px;
$screen-small: 768px;
$extra-screen-small: 480px;

// device sizes
$mobile-small: 320px;
$mobile-medium: 350px;
$mobile-large: 375px;

$button-default: #989A96;
$main: #01447b;
$secondary: #99b4ca;
$light: #ededed;
$secondary: #99b4ca;
$secondary-light: #cddae5;
$white: #ffffff;
$black: #000000;
$background-light: #f7f7f7;
$grey-blue: #cddae5;
$primary: #989A96;
$primary-light: #ADAFA9;
// $primary: #f7a901;
// $primary-light: #fdeecd;
$grey: #707070;
$yellow: #f7a901;
$yellow-light: #fef6e5;
$button-primary: #f8ab01ff;
$green: #02aa6f;
$grey-light: #d2d2d2;
$red: #e44747;

// Fonts

$base-font: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
