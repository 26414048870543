@use '../../variables' as *;

.search-results-page {
  max-width: 1440px;
  margin: 0 auto;

  .search-bar-wrapper {
    max-width: 1440px;
    margin: 42px auto 0px;
    position: relative;

    &--no-index {
      z-index: 0;
    }

    .search-page-main-heading {
      // color: $main;
      font-size: 1.1rem;
      margin-top: 0;
      margin-bottom: 0;
      // padding: 0 10px;

      @media screen and (min-width: 470px) {
        font-size: 1.25rem;
      }

      @media screen and (min-width: 640px) {
        font-size: 1.75rem;
        margin-bottom: 0;
      }

      @media (min-width: 1024px) {
        margin: 0;
        font-size: 40px;
      }
    }

    .search-page-top-wrapper__btn-wrap {
      display: none;

      @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
      }

      .sort-by-wrapper {
        display: none;
      }

      @media screen and (min-width: 1024px) {
        .sort-by-wrapper {
          display: block;
        }

        > div {
          margin-right: 2rem;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .search-results-container {
      margin: 0 10px;
    }

    @media screen and (min-width: 768px) {
      .search-results-container {
        display: flex;
        gap: 1rem;
      }
    }

    .holiday-card-container {
      @media screen and (max-width: 1023px) {
        margin: 0 auto;
      }

      @media screen and (min-width: 1024px) {
        margin-left: auto;
      }

      .holiday-card-wrapper {
        margin-bottom: 1rem;
      }
    }
  }

  .hidden {
    display: none;
  }

  .search-bar-wrapper.hidden {
    @media screen and (max-width: 1024px) {
      display: block !important;
    }
  }
}

/**
 * Search Page Top Wrapper
 */

.search-page-top-wrapper {
  display: flex;
  padding: 0 0.5rem;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 42px;
  }

  p {
    margin: 0;
  }
}
