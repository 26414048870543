@use '../../variables' as *;

.package-select-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  padding: 1rem;
}

.package-select-loader-modal {
  background: white;
  border: 1px solid $secondary-light;
  padding: 2rem;
  height: 100%;
  max-height: 450px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: none;
  text-align: center;
  // color: $main;

  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  &__spinner {
    margin: 1rem 0;
  }

  &__logos {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    img:first-of-type {
      margin-right: 2rem;
    }
  }
}

.package-select-loader-logo {
  margin-bottom: 2rem;
}

.pak-fake {

  .fake-paragraph {
    margin-left: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: $secondary-light;
    margin-bottom: 16px;
  
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .fake-paragraph-xs {
    width: 62px;
  }
  .fake-paragraph-s {
    width: 144px;
  }
  .fake-paragraph-m {
    max-width: 325px;
  }
  .fake-paragraph-l {
    max-width: 500px;
  }
  
  .fake-image {
    aspect-ratio: 4/3;
    background-color: $secondary-light;
    border-radius: 5px;
    margin: 2em 0;
  }
  .select-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }  
  
  .load-holiday-card-container {
    width: 350px;
    border-radius: 6px;
    background-color: $secondary-light;
  }  
  
  .gradient-animation {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, $white 80%, #f1f5f9 100%, #f1f5f9 85%);
    background-size: 500px 500px;
  
    position: relative;
  }


}

