@import '../../variables';

/* Mobile styles */

.page-footer {
  .trustpilot-widget,
  .footer-information,
  .footer-links-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 1.5rem 1rem;
    z-index: -5;
  }

  .footer-newsletter-container {
    background-color: $primary;
    margin-bottom: 2em;
  }

  .footer-newsletter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // color: $main;
    padding: 1.5rem 1rem;
    max-width: 1400px;
    margin: 0 auto;
  }

  .footer-newsletter h3,
  p {
    margin: 0;
    text-align: center;
  }

  .footer-newsletter h3 {
    text-transform: uppercase;
  }

  button {
    margin: 1em 0 0;
    width: auto !important;
    text-transform: uppercase;
    white-space: nowrap;
  }

  a.button {
    width: auto;
    text-decoration: none;
    text-transform: uppercase;
  }

  .footer-socials-mobile {
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-social-icons {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .footer-socials-desktop {
    display: none;
  }

  .footer-information {
    text-align: center;
    font-size: 13px;
    // color: $main;
    border-top: 3px solid $primary;
  }

  .footer-information-copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-bottom: 2em;
  }

  .footer-information-images {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  /* Media queries */

  @media screen and (min-width: 768px) {
    .trustpilot-widget,
    .footer-information {
      padding: 1.5rem 4rem;
    }
    .footer-newsletter {
      flex-direction: row;
      justify-content: space-between;
      gap: 2em;
      padding: 1.5rem 4rem;
    }

    .footer-newsletter-copy {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 2em;
    }

    button {
      margin: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .footer-links-container {
      max-width: 1400px;
      margin: 0 auto;
      padding: 1.5rem 4rem;
    }

    .footer-links {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      ul a {
        // color: $main;
        text-decoration: underline;
      }
    }

    .footer-socials-mobile {
      display: none;
    }
    .footer-socials-desktop {
      display: flex;
      justify-content: flex-end;
    }

    .footer-information {
      text-align: start;
    }
    .footer-information-copyright {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }
  }

  @media screen and (min-width: 1400px) {
    .trustpilot-widget,
    .footer-information,
    .footer-newsletter,
    .footer-links-container {
      padding: 1.5rem 0;
    }
  }
}
