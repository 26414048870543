@import '../../variables';

.pay-method-con
{
  @media screen and (min-width: 1079px) {
    display: flex;  
    flex-direction: row-reverse;
    gap: 20px;
  }

  .pc2{
    @media screen and (min-width: 1079px) {
      width:600px;  
    }
  }

}



.credit-card-container {
  background-color: white;
  width: 100%;
  border: 1px solid $secondary-light;
  border-radius: 12px;
  padding: 15px 15px;

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
  }
}

.credit-card-cvv-wrap {
  display: flex;
  gap: 16px;
}

.credit-card-direct-debit-footer {
  border-top: 1px solid $grey-light;
  display: flex;
  padding-top: 1.5rem;

  &__text {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 2rem;
  }
}

.credit-card-payment-overview {
  border: 3px solid black;
  border-radius: 8px;
  background-color: #f3f5f7;
  padding: 0.5rem 1rem;
}

.credit-card-title {
  font-size: 14px;
  margin-top: 0px;
  // color: $main;
}

.credit-card-title > span {
  margin-left: 5px;
}

.credit-card-icon {
  position: relative;
  top: 10px;
}

.credit-card-button {
  font-weight: 700;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 20px 0;
  width: 100%;
}

.credit-card-button-container .button--label {
  font-size: 25px;
  position: relative;
  top: 0px;
  font-weight: lighter;
  color: $white;
}

.credit-card-button-container .button {
  background-color: $black;
  height: 45px;
  padding: 2px;
}

.grid-3by3 {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.grid-3-3 {
  display: grid;
  margin-top: 10px;
  grid-template-columns: auto 120px auto;
}
.grid-3-3-safe-checkout {
  margin: 1.5em 0;
  border-color: $secondary;
  color: $secondary;
}
.grid-2-2 {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.grid-4-3 {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 2fr 2fr 1.5fr 0.5fr;
  gap: 15px;
}

select::-ms-expand {
  display: none;
}

select {
  /* styling */
  background-color: white;
  border: thin solid $secondary-light;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* arrows */

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, $main 50%),
    linear-gradient(135deg, $main 50%, transparent 50%), linear-gradient(to right, $main, $main);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: none;
  outline: 0;
  z-index: 100;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 10px;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.credit-card-pay-with-card:before,
.credit-card-pay-with-card:after {
  content: '';
  display: inline-block;
  width: 50%;
  color: $secondary;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
  overflow: hidden;
}
.credit-card-pay-with-card:after {
  margin: 0 -55% 0 0.5em;
}

.credit-card-pay-with-card {
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  // color: $main;
  text-transform: uppercase;

  &--dd {
    color: $grey-light;
    text-transform: none;
    font-weight: bold;
    margin: 2rem 0;

    @media screen and (min-width: 1024px) {
      margin: 1rem 0 2rem;
    }

    &:before,
    &:after {
      color: $grey-light;
    }
  }
}

.credit-card-input-container {
  position: relative;
  margin-bottom: 1rem;
}

.credit-card-label {
  font-weight: bold;
  // color: $main;
  font-size: 14px;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.credit-card-select {
  font-weight: bold;
  // color: $main;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 6px 10px;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid $secondary-light;
}

.credit-card-input {
  font-weight: bold;
  // color: $main;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0px 10px;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid $secondary-light;
  height: 40px;
}

.credit-card-input input{
  width: 100%;  
  height: 100%;
  border: none;
  outline: none;
  
}

.credit-card-cvv {
  position: relative;
  top: 15px;
  display: inline-block;
}

.credit-card-overview {
  text-align: center;
  margin-bottom: 1.5em;
  // color: $main;
}

.credit-card-overview-p {
  font-size: 14px;
  // color: $main;
  margin-top: 15px;
}

// .credit-card-overview-p a {
//   color: $main;
// }

.credit-card-book-now {
  margin-top: 1.5em;
}

.credit-card-hidden {
  display: none;
}

.credit-card-align-right {
  text-align: right;
  top: 30px;
  position: relative;
}

.credit-card-ssl {
  top: -40px;
  color: $green;
  position: relative;
  display: inline-block;
}

.credit-card-align-center {
  text-align: center;
  position: relative;
}

.required-field {
  font-size: 12px;
  color: $red;
}

.credit-card-loader {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.credit-card-loader-text {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 22px;
  // color: $main;
}
.credit-card-rel-container > .loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  margin: 8px;
  border: 4px solid black;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}

.credit-card-rel-container {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}

.credit-card-img-padlock-absolute {
  position: absolute;
  left: 40px;
  top: 35px;
}

.credit-card-loader-tag {
  margin-top: 50px;
  font-size: 20px;
  // color: $main;
}

.credit-card-circle {
  height: 140px;
  width: 140px;
  border-radius: 90px;
  border: 4px solid $main;
}

.credit-card-tick {
  top: 15px;
  position: relative;
}

.credit-card-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.credit-card-rel-container > .loader-ring {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 120px;
}

.credit-card-mastercard-img,
.credit-card-visa-img,
.credit-card-ssl-img {
  width: 100px;
}

@media (max-width: $screen-medium) {
  .grid-1-1-mob {
    grid-template-columns: 1fr;
  }
  .grid-2-2-mob {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
  }

  .grid-custom-mob {
    grid-template-columns: 50px 65%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
  }
  .grid-3by3-mob {
    display: grid;
    grid-template-columns: 100px 100px auto;
    gap: 10px;
    justify-content: center;
  }

  .credit-card-mastercard-img,
  .credit-card-visa-img,
  .credit-card-ssl-img {
    width: 60px;
  }

  .grid-1-1-mob .credit-card-align-right {
    text-align: center;
    display: block;
  }
  .align-center-mob {
    text-align: center;
    position: relative;
  }
  .credit-card-ssl {
    top: 15px;
    left: 5px;
  }
  .credit-card-align-right {
    text-align: right;
    top: 10px;
    position: relative;
  }
}

@media (max-width: $mobile-large) {
  .grid-3by3-mob {
    display: grid;
    grid-template-columns: 80px 100px auto;
    gap: 0px;
  }
  .credit-card-ssl-container {
    top: -10px;
    position: relative;
  }
}

.credit-card-error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 1.5rem;
  background-color: #dc0000;
  margin: 2rem 0;

  .credit-card-error__text {
    margin: 0;
    font-weight: 500;
  }

  .credit-card-error__left {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  button {
    appearance: none;
    border: none;
    background: transparent;
    display: inline;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

/**
 * Terms for Credit Card Payments
 */
.credit-card-terms {
  margin: 1.5em 0 2em;
  font-size: 1rem;
  // color: $main;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }

  input {
    margin-right: 1rem;
  }

  .credit-card-terms__link {
    // color: $main;
    font-weight: 500;
  }
}
