@import '../../variables';

.tripadvisor-hotel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .tripadvisor-hotel-title {
    font-size: 24px;
    font-weight: bold;
  }
  .tripadvisor-ratings-section {
    display: flex;

    .tripadvisor-rating {
      display: flex;
      align-items: center;
      .tripadvisor-rating-icon {
        height: 20px;
      }
      .rating {
        margin-left: 8px;
      }
      .tripadvisor-rating-reviews {
        font-size: 10px;
        font-weight: 600;
        margin-left: 8px;
        color: $grey;
      }
    }
  }
  .tripadvisor-location {
    font-size: 12px;
    font-weight: 600;
    color: $grey;
  }
}

@media (min-width: $screen-medium) {
  .tripadvisor-hotel-wrapper {
    gap: 12px;

    .tripadvisor-hotel-title {
      font-size: 28px;
      font-weight: bold;
    }

    .tripadvisor-ratings-section {
      .tripadvisor-rating {
        .tripadvisor-rating-reviews {
          font-size: 12px;
          font-weight: 500;
          margin-left: 12px;
        }
      }
    }
    .tripadvisor-location {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
