@import '../../variables';

.tripadvisor-reviews-wrapper {
  .tripadvisor-reviews-top-section {
    display: flex;
    flex-direction: column;

    .tripadvisor-logo-wrapper {
      margin-bottom: 20px;
    }
  }

  .tripadvisor-votes-title {
    font-size: 24px;
    font-weight: normal;
    margin: 12px 0;
  }
  
  .tripadvisor-reviews-list {
    .tripadvisor-review {
      border-bottom: 1px solid $grey-light;
      padding-bottom: 12px;
      margin-bottom: 12px;

      &:last-child {
        border-bottom: none;
      }

      .tripadvisor-rating-with-date {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 12px;
        color: $grey;
      }

      .tripadvisor-review-user-info {
        font-size: 12px;
        color: $grey-light;
        margin-top: 8px;
      }
    }
  }

  .rating {
    margin: 0;

    .circle {
      height: 16px;
      width: 16px;
    }
  }
}

@media (min-width: $screen-medium) {
  .tripadvisor-reviews-wrapper {
    .tripadvisor-reviews-top-section {
      flex-direction: row-reverse;
      justify-content: space-between;

      .tripadvisor-logo-wrapper {
        margin-bottom: 0;
      }
    }

    .tripadvisor-votes-title {
      font-size: 28px;
      margin: 16px 0;
    }

    .tripadvisor-reviews-list {
      .tripadvisor-review {
        .tripadvisor-rating-with-date {
          font-size: 16px;
        }

        .tripadvisor-review-user-info {
          font-size: 14px;
        }
      }
    }

    .rating {
      .circle {
        height: 20px;
        width: 20px;
      }
    }
  }
}
