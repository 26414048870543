@use './src/variables' as *;

.datepicker-wrapper {
  position: relative;
  font-family: $base-font;
  width: 100%;

  // @media screen and (min-width: 1024px) {
  //   max-width: 205px;
  // }
}

// .datepicker-wrapper .selected-date {
//   color: $main;
// }

.react-calendar__month-view__days .hide-date abbr {
  display: None !important;
  color: green;
}

.react-calendar__month-view__days .disabled-date:hover {
  cursor: default !important;
  background-color: white !important;
}

.react-calendar__month-view__days .disabled-date {
  color: $secondary-light !important;
}

.datepicker-wrapper .disabled-date {
  color: $secondary-light !important;
}

.datepicker-wrapper .disabled-date:hover {
  background-color: $white !important;
  cursor: default !important;
}

.input-field-wrapper {
  z-index: 1;
}

.react-date-picker {
  position: absolute;
  bottom: -5px;
  left: 0;
  z-index: -1;
}

.datepicker-wrapper-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px #c3d3e0;
  border-radius: 5px;
  position: absolute;
  top: 85px;
  background-color: $white;
  z-index: 1;

  @media screen and (min-width: 1200px) {
    width: 386px;
  }
}

.datepicker-wrapper-inputs .hwt-toggle {
  margin: 15px auto;
}

.react-datepicker {
  margin-top: 20px;
  border-top: solid 1px;
}

.datepicker-component-wrapper {
  margin-top: 10px;
  padding-top: 30px;
  border-top: solid 1px $secondary;
}

.dateNoMonthSel{
  border-top: none;
  padding-top: 15px;

}
.datepicker-large-view {
  display: block;
  z-index: 100;
}

.datepicker-mobile-view {
  display: none;
}

@media (max-width: $screen-small) {
  .datepicker-mobile-view {
    display: block;
  }
  .datepicker-large-view {
    display: none;
  }
  .modal-content .hwt-toggle {
    width: 240px;
    margin: 10px auto 10px;
  }

  .month-picker-wrapper--item .year-wrapper .months span {
    padding: 5px;
  }
}

.datepicker-overlay {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
