@use './src/variables' as *;

.holiday-card-wrapper {
  display: grid;
  border-radius: 10px;
  font-family: $base-font;
  background: white;

  @media screen and (min-width: 1280px) {
    grid-template-columns: 400px 1fr;
    width: 1000px;
  }

  // Make splide instance rounded
  .image-carousel {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    border-bottom: solid 1px #cddae5;
    border-top: solid 1px #cddae5;
    border-left: solid 1px #cddae5;
  }

  @media screen and (min-width: 1280px) {
    .image-carousel {
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
    }
  }

  &--carousel-wrapper {
    max-width: 400px;
    height: 100%;
    object-fit: cover;

    .image-carousel {
      height: 100%;

      .navigation-wrapper {
        height: 100%;

        .keen-slider {
          min-height: 100%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    border: solid 1px $secondary-light;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media screen and (min-width: 1280px) {
      border-left: none;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0;
    }

    .name-section-wrapper {
      border-bottom: solid 1px $secondary-light;
    }

    .holiday-details-wrapper {
      border-bottom: solid 1px $secondary-light;
    }

    .footer-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 25px;

      .price-wrapper {
        span {
          display: flex;

          img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
            align-self: flex-end;
          }
        }
      }

      button {
        width: 100px;
      }
    }
  }

  @media (max-width: $screen-small) {
    display: block;
    grid-template-columns: 1fr;
    max-width: 100vw;

    &--carousel-wrapper {
      max-width: 100vw;

      .image-carousel {
        width: 100vw;
        .navigation-wrapper {
          border-radius: 5px 5px 0px 0px;

          .keen-slider {
            border-radius: 5px 5px 0px 0px;
          }
        }
      }
    }

    &--details {
      border: solid 1px $secondary-light;
      border-top: none;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 5px;

      .top-section {
        padding: 20px 14px;
        .main-info-wrapper {
          .name-section {
            .name {
              font-size: 16px;
            }
          }
        }

        .location {
          font-size: 16px;
        }

        .tripadvisor-rating {
          .trip-logo {
            width: 24px;
            height: 15px;
          }

          .circle {
            width: 14px;
            height: 14px;
          }

          .fullrate {
            height: 14px;
          }
        }
      }

      .middle-section {
        padding: 20px 30px;
      }

      .footer-section {
        flex-direction: column;
        align-items: end;
        padding: 15px;

        .price-wrapper {
          display: flex;
          color: $main;
          margin-bottom: 20px;

          .sale-price {
            margin-left: 20px;

            img {
              transform: scale(1.5);
            }
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $extra-screen-small) {
  .holiday-card-wrapper--carousel-wrapper {
    max-width: 100vw;
  }
  .holiday-card-wrapper {
    max-width: 100vw;
  }
}

.image-carousel .navigation-wrapper {
  border-radius: 0;
}

.parent {
  overflow: hidden; /* required */
  width: 50%; /* for demo only */
  height: 250px /* some non-zero number */;
  margin: 25px auto; /* for demo only */
  border:1px solid grey; /* for demo only */
  position: relative; /* required  for demo*/
}

.ribbon {
  z-index: 1;
  margin: 0;
  padding: 0;
  background: rebeccapurple;
  color:white;
  padding:1em 0;
  position: absolute;
  top:12px;
  right:12px;
  padding: 10px;
  border-radius: 10px;
}

.offer-flight-details-wrapper {
  background: $white;
  padding: 0 20px 20px 20px;
  position: absolute;
  top: 25px;
  left: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
  z-index: 10;

  .flight-details-wrapper {
    .info-section {
      span {
        margin-left: 0;
      }
    }

    .flight-details--main-section {
      .route-point {
        .flight-time {
          margin-left: 0;
        }
      }
    }
  }

  .flight-details-wrapper + .flight-details-wrapper {
    border-top: solid 1px $secondary-light;
  }
}
