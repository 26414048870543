@use './src/variables' as *;

.loader {
  .fake-main-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-loader-main-heading {
      // color: $main;
      font-size: 24px;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }
  }

  .filters-wrapper-desktop {
    width: 421px;
  }

  .fake-image {
    aspect-ratio: 4/3;
    background-color: $secondary-light;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .fake-toggle {
    height: 44px;
    width: 317px;
    border-radius: 44px;
    background-color: $secondary-light;
  }
  .fake-heading {
    height: 26px;
    width: 137px;
    border-radius: 4px;
    background-color: $secondary-light;
    margin-bottom: 24px;
  }
  .fake-paragraph {
    height: 14px;
    border-radius: 2px;
    background-color: $secondary-light;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .fake-paragraph-s {
    width: 95px;
  }
  .fake-paragraph-m {
    width: 144px;
  }
  .fake-paragraph-l {
    width: 187px;
  }
  .fake-paragraph-xl {
    width: 239px;
  }

  .price-section {
    gap: 32px;

    .fake-heading {
      margin-bottom: 0;
    }
  }

  /**
   * Styles for fake sidebar
   */
  .fake-sidebar {
    border: 1px solid $secondary-light;
    border-radius: 0.5rem;
    overflow: hidden;

    .fake-sidebar__header {
      background-color: $secondary-light;
      width: 100%;
      height: 36px;
      margin-bottom: 0.5rem;
    }

    .fake-sidebar__button {
      background-color: $secondary-light;
      width: 100%;
      height: 40px;
      margin-bottom: 0.5rem;
      border-radius: 4px;
    }

    .fake-sidebar__body {
      padding: 1rem;

      > div {
        margin-bottom: 0.75rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .fake-sidebar__text {
        height: 10px;
        border-radius: 2px;
        background-color: #cddae5;

        &--wide {
          width: 75%;
        }

        &--half {
          width: 50%;
        }

        &--quarter {
          width: 25%;
        }
      }

      .fake-sidebar__heading {
        height: 18px;
        width: 50%;
        border-radius: 4px;
        background-color: #cddae5;

        &--short {
          width: 20%;
        }
      }

      .fake-sidebar__inline-wrap {
        display: flex;
        justify-content: space-between;
      }
    }

    .fake-sidebar__divider {
      height: 1px;
      width: 100%;
      background-color: $secondary-light;
      opacity: 0.8;
      margin: 1.5rem 0 0;
    }

    .fake-sidebar__summary {
      display: flex;
      justify-content: space-between;

      .fake-sidebar__summary-right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .fake-detail {
    .fake-detail__divider {
      height: 1px;
      width: 100%;
      background-color: $secondary-light;
      opacity: 0.8;
      margin: 2.5rem 0;
    }

    .fake-detail__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.8rem;
      margin-top: 2rem;
    }

    .fake-detail__spacer {
      height: 24px;
    }
  }

  .gradient-animation {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, $white 80%, #f1f5f9 100%, #f1f5f9 85%);
    background-size: 500px 500px;

    position: relative;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 0px 0;
    }
  }
}
