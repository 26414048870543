@import '../../variables';

.search-bar-container {
  max-width: 1390px;
  margin: 0 auto;
  padding: 0 10px;

  .button--search {
    font-size: 18px;
  }
  .selected-value,
  .selected-date {
    font-weight: 300;
  }

  .input-field-main-mobile {
    background-image: url('data:image/svg+xml,%3C%3Fxml version=%271.0%27 encoding=%27utf-8%27%3F%3E%3Csvg version=%271.1%27 id=%27Layer_1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 x=%270px%27 y=%270px%27 width=%27122.879px%27 height=%27119.799px%27 viewBox=%270 0 122.879 119.799%27 enable-background=%27new 0 0 122.879 119.799%27 xml:space=%27preserve%27%3E%3Cg%3E%3Cpath d=%27M49.988,0h0.016v0.007C63.803,0.011,76.298,5.608,85.34,14.652c9.027,9.031,14.619,21.515,14.628,35.303h0.007v0.033v0.04 h-0.007c-0.005,5.557-0.917,10.905-2.594,15.892c-0.281,0.837-0.575,1.641-0.877,2.409v0.007c-1.446,3.66-3.315,7.12-5.547,10.307 l29.082,26.139l0.018,0.016l0.157,0.146l0.011,0.011c1.642,1.563,2.536,3.656,2.649,5.78c0.11,2.1-0.543,4.248-1.979,5.971 l-0.011,0.016l-0.175,0.203l-0.035,0.035l-0.146,0.16l-0.016,0.021c-1.565,1.642-3.654,2.534-5.78,2.646 c-2.097,0.111-4.247-0.54-5.971-1.978l-0.015-0.011l-0.204-0.175l-0.029-0.024L78.761,90.865c-0.88,0.62-1.778,1.209-2.687,1.765 c-1.233,0.755-2.51,1.466-3.813,2.115c-6.699,3.342-14.269,5.222-22.272,5.222v0.007h-0.016v-0.007 c-13.799-0.004-26.296-5.601-35.338-14.645C5.605,76.291,0.016,63.805,0.007,50.021H0v-0.033v-0.016h0.007 c0.004-13.799,5.601-26.296,14.645-35.338C23.683,5.608,36.167,0.016,49.955,0.007V0H49.988L49.988,0z M50.004,11.21v0.007h-0.016 h-0.033V11.21c-10.686,0.007-20.372,4.35-27.384,11.359C15.56,29.578,11.213,39.274,11.21,49.973h0.007v0.016v0.033H11.21 c0.007,10.686,4.347,20.367,11.359,27.381c7.009,7.012,16.705,11.359,27.403,11.361v-0.007h0.016h0.033v0.007 c10.686-0.007,20.368-4.348,27.382-11.359c7.011-7.009,11.358-16.702,11.36-27.4h-0.006v-0.016v-0.033h0.006 c-0.006-10.686-4.35-20.372-11.358-27.384C70.396,15.56,60.703,11.213,50.004,11.21L50.004,11.21z%27/%3E%3C/g%3E%3C/svg%3E');
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: 21px 23px;
    width: 100%;
    border-radius: 6.25rem;
    height: 68px;
    padding-left: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;

    .input-field--title {
      display: block;
      color: $main;
      font-size: 14px;
      margin: 10px 0;
    }

    .selected-value {
      font-size: 12px;
      font-weight: 300 !important;
      color: $secondary;
    }

    .icon-wrapper {
      display: none;
    }
  }

  .search-bar-input-field-error {
    margin: 0.25rem 0;
    color: red;
  }

  .search-bar-input-field-autocomplete {
    position: relative;
    width: 100%;

    &.destination-select--wide {
      min-width: 185px;
    }

    // &.duration-wrapper {
    //   width: calc(60% - 5px);
    // }

    .reset-button {
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;

      @media screen and (min-width: 769px) {
        top: 35px;
      }

    }
  }

  .datepicker-wrapper {
    .reset-button {
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;

      @media screen and (min-width: 769px) {
        top: 35px;
      }

    }
  }




  .collapsed-search-options {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // gap: 12px;

    /**
     * This will be the desktop styles, working on a grid pattern
     * for now.
     */
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    gap: 12px;





    @media screen and (min-width: 1200px) {
      // grid-template-columns: repeat(6, 1fr);
      grid-template-columns: 1fr 1fr 1fr 125px 1fr 1fr;
    }

   

    .search-bar-overlay {
      position: fixed;
      top: 270px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $main;
      opacity: 0.5;
      z-index: 1;
      overflow-y: hidden;
      height: 100%;

      &--with-options {
        top: 350px;
      }
    }
  }

  .rooms-select-container {
    position: relative;
    width: 100%;
    min-width: 210px;
  }

  .rooms-container {
    position: relative;
    background-color: $white;
    z-index: 11;
    left: 0;
    padding: 16px;
    border-radius: 4px;
    width: 100%;
    height: 100%;

    .button {
      margin-bottom: 16px;
    }
  }
}
.pak_refine{
  @media screen and (min-width: 1200px) {
    // grid-template-columns: repeat(5, 1fr);
    grid-template-columns: 1fr 1fr 125px 1fr 1fr !important;
  }
}
.search-button-wrapper {
  min-width: 328px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
  border: 1px solid $secondary;
  border-radius: 35px;
  cursor: pointer;

  span {
    border-right: 2px solid $secondary;
    padding: 8px 16px;
    font-weight: 500;

    &:last-child {
      border-right: none;
      margin-right: 12px;
    }
  }

  .search-button-text {
    // color: $main;
    font-size: 15px;
    font-weight: bold;
  }
  .search-button-icon {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: $button-default;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/**
 * Option Selection
 */

.search-options {
  display: flex;
  justify-content: center;
}

.search-option {
  cursor: pointer;
  // color: #01447b;
  margin: 0 1rem;
  text-align: center;
  opacity: 0.5;
  padding-bottom: 1rem;
}

.search-option p {
  margin: 0;
}

.search-option.search-option--active {
  opacity: 1;

  &::after {
    // background-color: #01447b;
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    top: 0.75rem;
  }
}

.search-bar-container .duration-wrapper {
  .input-field-wrapper {
    min-width: unset;
  }
}

@media (min-width: $screen-small) {
  .search-bar-container {
    .input-field-main-mobile {
      display: none;
    }

    .collapsed-search-options {
      // flex-wrap: nowrap;

      .button--search {
        // height: 64px;
        height: 100%;
        border: 1px solid transparent;
        position: relative;
        top: -1px;
      }
    }

    .rooms-container {
      position: absolute;
      border: 1px solid $secondary;
      top: 85px;
      right: 0;
      width: 100%;
      height: auto;
      left: unset;

      @media screen and (min-width: 1200px) {
        width: 410px;
        max-width: 410px;
      }
    }
  }
}

@media (max-width: $screen-medium) {
  .wrapper {
    gap: 20px;
  }

  .search-button-wrapper {
    span {
      border-right: 2px solid $secondary;
      padding: 8px 8px;
    }
  }

  .search-button-wrapper {
    padding: 9px 0px 9px 0px;
    max-width: 720px;

    .search-button-values {
      padding-left: 10px;
    }
  }
  .search-button-wrapper .search-button-icon {
    position: relative;
    right: 8px;
  }

  .search-bar-container .collapsed-search-options {
    // display: grid;
    // grid-template-columns: 33.3% 33.3% 33.3%;
    // padding: 25px;
    // padding-top: 0px;
    // padding-bottom: 0px;
    // gap: 8px;
  }

  .search-bar-container .rooms-select-container {
    width: 100%;
  }

  .search-bar-container .search-bar-input-field-autocomplete.duration-wrapper {
    width: 100%;
  }

  .search-bar-container .collapsed-search-options .search-bar-overlay {
    top: 408px;
  }
}

@media (max-width: $screen-small) {
  .search-bar-container {
    padding-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 5px 5px -5px rgb(0 0 0 / 25%);
  }

  .search-bar-container .collapsed-search-options {
    display: flex;
    grid-template-columns: 33.3% 33.3% 33.3%;
    padding: 2px;
  }

  .search-bar-container .collapsed-search-options .search-bar-overlay {
    display: none;
  }

  .collapsed-search-options {
    gap: 0px !important;
    > div {
      margin-bottom: 10px;
    }

    .collapsed-search-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 12px;

      .search-bar-overlay {
        position: fixed;
        top: 270px;
        bottom: 0;
        left: 0;
        right: 0;
        // background-color: $main;
        opacity: 0.5;
        z-index: 1;
        overflow-y: hidden;
        height: 100%;
      }
    }

    .rooms-select-container {
      position: relative;
    }

    .rooms-container {
      background-color: $white;
      z-index: 11;
      padding: 16px;
      border-radius: 4px;
      width: 100%;
      height: calc(100% - 140px);

      .button {
        margin-bottom: 32px;
      }
    }
  }
  .search-bar-container .collapsed-search-options {
    flex-wrap: wrap;
  }
}

.search-bar-error {
  color: red;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.tabTypeLine{
  display:none;
}


.btn{
  width: 168px;
  height: 48px;
}

.btnExt{
  margin-top: 35px;
}


.btn .button--label {
  font-weight: 600;
}

.btn button{
  border-radius: 6.25rem;
}
.btn--mob{
  width: 100%;
  display: none;
}

@media (max-width: 1023px) {
  .search-option{
    color: #01447b !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .tabTypeLine{
    display:block;
  }

  .btn{
    display: none;
  }
  .btn--mob{
    width: 100%;
    display: block;
  }
  
  .button--icon{
    margin-left: 10px;
  }

}

.input-error{
  border-color: red !important;
  border-width: 1px;
  color:red;
}

.input-error-text span{
  color:red !important;
}