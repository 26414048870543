@import './variables';

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.search-bar-wrapper {
  // background-color: white;
  // position: relative;
  // margin: 32px 0;

  @media screen and (min-width: 768px) {
    z-index: 500;
  }
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 900;
  src: local('GothamSSm-Black_Web'), url('./fonts/GothamSSm-Black_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 900;
  font-style: italic;
  src: local('GothamSSm-BlackItalic_Web'),
    url('./fonts/GothamSSm-BlackItalic_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 700;
  src: local('GothamSSm-Bold_Web'), url('./fonts/GothamSSm-Bold_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 700;
  font-style: italic;
  src: local('GothamSSm-BoldItalic_Web'),
    url('./fonts/GothamSSm-BoldItalic_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 400;
  font-style: italic;
  src: local('GothamSSm-BookItalic_Web'),
    url('./fonts/GothamSSm-BookItalic_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 400;
  src: local('GothamSSm-Book_Web'), url('./fonts/GothamSSm-Book_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 300;
  font-style: italic;
  src: local('GothamSSm-LightItalic_Web'),
    url('./fonts/GothamSSm-LightItalic_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 300;
  src: local('GothamSSm-Light_Web'), url('./fonts/GothamSSm-Light_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 500;
  font-style: italic;
  src: local('GothamSSm-MediumItalic_Web'),
    url('./fonts/GothamSSm-MediumItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'GothamSSm';
  font-weight: 500;
  src: local('GothamSSm-Medium_Web'), url('./fonts/GothamSSm-Medium_Web.woff') format('woff');
}

@font-face {
  font-family: 'GothamSSm';
  font-weight: 200;
  font-style: italic;
  src: local('GothamSSm-XLightItalic_Web'),
    url('./fonts/GothamSSm-XLightItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'GothamSSm';
  font-weight: 200;
  src: local('GothamSSm-XLight_Web'), url('./fonts/GothamSSm-XLight_Web.woff') format('woff');
}

/**
 * Screen Reader Utility
 */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.headTelSec{
  min-width: 250px;
}

.telephone{
  text-decoration: none;
  color:#01447B;
}


.telephone a {

  color:#01447B; 
  font-size:22px;
  text-decoration: none;
  font-weight: 500;
}

.telType{
  margin-top: 0px;
  font-size: 13px;
}

.error{
  color:red;
}

.container{
  background-color: white;
}

.journey-button {
  font-weight: 700;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0px;
  height: 35px;
  width: 130px;
  border-radius: 20px;
  color: $secondary-light;
  background-color: transparent;
  border: 1px solid $secondary-light;

  @media screen and (min-width: 768px) {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  &-active {
    border-color: $primary;
    color: $primary;
  }
}