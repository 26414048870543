.sort-by-wrapper {
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* color: #01447b; */
  font-size: 1em;
}

.sort-by-component > select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='451.847px' height='451.847px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='currentColor' d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751 c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0 c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 1rem) 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

@media screen and (min-width: 640px) {
  .sort-by-component > select {
    background-position: 214px 17px;
  }
}

.sort-by-title,
.sort-by-select {
  font-size: 16px;
  font-weight: 300;
}

.sort-by-title {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  .sort-by-title {
    margin-bottom: 0;
  }
}

.sort-by-component .sort-by-select {
  width: 100%;
  padding: 0.75rem 1.5rem;
  /* color: #01447b;
  border-color: #01447b; */
  border-radius: 7px;
}

@media screen and (min-width: 640px) {
  .sort-by-component .sort-by-select {
    width: 250px;
  }
}

.sort-by-component {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .sort-by-component {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 640px) {
  .sort-by-component {
    justify-content: flex-start;
  }

  .sort-by-component .sort-by-title {
    margin-right: 1rem;
  }
}

.sort-by-mobile {
  display: none;
}

.sort-by-title--small,
.sort-by-select--small,
.sort-by-checkbox--small {
  font-size: 12px;
}

.sort-by-title--medium,
.sort-by-select--medium,
.sort-by-checkbox--medium {
  font-size: 14px;
}

.sort-by-title--large,
.sort-by-select--large,
.sort-by-checkbox--large {
  font-size: 16px;
}
