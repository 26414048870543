.payment-options {
  border: 1px solid #99b4ca;
  border-radius: 4px;
  padding: 1rem 1.25rem;
  font-size: 14px;
  // color: #01447b;

  .payment-options__title {
    margin: 0;
  }

  .payment-options__option {
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .payment-options__option-title {
    display: block;
    margin-bottom: 1rem;
  }

  .payment-options__option-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .payment-options__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .payment-options__icon {
    // border-color: #01447b;
    border-style: solid;
    border-width: 3px 3px 0 0;
    transform: rotate(135deg);
    height: 12px;
    width: 12px;
    position: relative;
    top: -2px;
    transition: transform 300ms ease-out;

    &--active {
      transform: rotate(315deg);
      top: 2px;
      transition: transform 200ms ease-out;
    }
  }
}
