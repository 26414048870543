@use './src/variables' as *;

.holiday-summary-wrapper {
  background-color: white;
  font-family: $base-font;

  .holiday-summary--header {
    // background-color: $main;
    padding: 20px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &__span {
      font-size: 14px;
      font-weight: bold;
      color: $white;
    }
  }

  .holiday-summary-body {
    border: solid 2px $main;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    padding: 20px 15px;
    margin-top: -1px;

    @media screen and (min-width: 1024px) {
      .panel-loader {
        min-height: 650px;
      }
    }

    .top-section {
      margin-bottom: 20px;

      &--title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 14px;
      }

      &--subtitle {
        font-size: 12px;
        font-weight: 300;
      }

      p {
        margin: 0;
      }

      p + p {
        margin-top: 5px;
      }
    }

    .middle-section {
      border-top: solid 1px $secondary-light;
      border-bottom: solid 1px $secondary-light;
      margin-bottom: 20px;

      .flight-details-wrapper + .flight-details-wrapper {
        border-top: solid 1px $secondary-light;
      }

      .airline-logo-text img {
        width: 100px;
      }
    }

    .bottom-section {
      .bottom-section__extras {
        border-bottom: 1px solid $secondary-light;
        padding-bottom: 20px;

        .bottom-section__extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.5rem;
          font-weight: 300;
          font-size: 14px;

          .bottom-section__extra-item {
            flex: 1 1 0%;
            margin-left: 1rem;
            // width: 75%;

            // &:first-of-type {
            //   width: 25%;
            // }
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          img {
            height: 24px;
            width: 24px;
          }
        }
      }

      &--price-info {
        .old-price {
          display: flex;
          justify-content: right;
          text-decoration: line-through;
        }

        .price-per-person {
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 16px;
            font-weight: bold;
          }

          .price {
            font-size: 18px;
            font-weight: bold;
            // color: $yellow;
          }
        }

        .total-price {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .title {
            font-size: 13px;
            font-weight: 500;
          }

          .price {
            font-size: 13px;
            font-weight: 500;
            // color: $yellow;
          }
        }
      }

      &--payment-options {
        margin-bottom: 12px;
        font-size: 14px;
      }
    }
  }
}

.summary-overlay{
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 50%;
}
