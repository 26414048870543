.hays-footer {
  font-family: system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;

  color: rgba(255, 255, 255, 0.87);
  // background-color: #242424;

  // font-synthesis: none;
  // text-rendering: optimizeLegibility;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  /* Variables */
  --selective-yellow: #fbba00;
  --regal-blue: #00437a;
  --blumine: #195687;
  --midnight-blue: #003662;
  --container-width: 1440px;
}

// body {
//   margin: 0;
//   padding: 0;
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   justify-content: flex-end;
// }

/* Footer */


.hays-footer {
  font-size: .99rem;
}

.hays-footer h2 {
  margin: 0;
}

.hays-footer a {
  color: #ffffff;
  text-decoration: none;
}

.hays-footer a:hover {
  text-decoration: underline;
}

.hays-footer .hays-footer__button {
  color: #ffffff;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--regal-blue);
  border-style: none;
  border-radius: 6.25rem;
  cursor: pointer;
  font-weight: 900;
}

/* Footer Banner */
.hays-footer .hays-footer__banner {
  background-color: var(--selective-yellow);
  color: var(--regal-blue);
}

.hays-footer .hays-footer__banner-container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 1rem;
}

.hays-footer .hays-footer__signup-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: start;
  gap: 1rem;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.hays-footer .hays-footer__signup-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    gap: 1.5rem;
  }
}

/* Grid */
.hays-footer .hays-footer__grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 2rem;

  @media screen and (min-width: 1280px) {
    gap: 5rem;
  }
}

.hays-footer .hays-footer__main-block {
  background-color: var(--regal-blue);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (min-width: 1024px) {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }
}

.hays-footer .hays-footer__container {
  margin: auto;

  @media screen and (min-width: 1280px) {
    max-width: 1280px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
}

/* Information */
.hays-footer .hays-footer__info-column {
  grid-column: span 12 / span 12;

  @media screen and (min-width: 1024px) {
    grid-column: span 4 / span 4;
  }
}

.hays-footer .hays-footer__info-icons {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.hays-footer .hays-footer__info-icon {
  object-fit: cover;
  height: auto;
}

.hays-footer .hays-footer__info-title {
  color: var(--selective-yellow);
  margin-bottom: 1rem;
}

.hays-footer .hays-footer__info-content {
  line-height: 1.455rem;
}

.hays-footer .hays-footer__info-content a {
  text-decoration: underline;
  color: var(--selective-yellow);
}

/* Contact Details */
.hays-footer .hays-footer__contact-details {
  padding: 1.5rem;
  background-color: var(--blumine);
  border-radius: 0.5rem;

  @media screen and (min-width: 1024px) {
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding: 2rem;
  }
}

.hays-footer .hays-footer__contact-details ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.hays-footer .hays-footer__contact-details ul li {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.hays-footer .hays-footer__contact-details ul li a {
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;

  @media screen and (min-width: 1024px) {
  }
}

.hays-footer .hays-footer__contact-details ul li:last-of-type {
  margin-bottom: 0;
}

.hays-footer .hays-footer__icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  background-color: var(--midnight-blue);
}

.hays-footer .hays-footer__icon-wrap svg {
  fill: #ffffff;
  height: 1.5rem;
  width: 1.5rem;
}

/* Links */
.hays-footer .hays-footer__links-column {
  grid-column: span 12 / span 12;

  @media screen and (min-width: 1024px) {
    grid-column: span 2 / span 2;
  }
}

.hays-footer .hays-footer__link-title {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--selective-yellow);
  margin-bottom: 1rem;
}

.hays-footer .hays-footer__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.hays-footer .hays-footer__links li {
  margin-bottom: 1rem;
}

.hays-footer .hays-footer__links li:last-of-type {
  margin-bottom: 0rem;
}

/* Legal and Social */
.hays-footer .hays-footer__legal-and-social {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #ffffff;
  padding-top: 1.5rem;
  margin-top: 1.5rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 3rem;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

.hays-footer .hays-footer__social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1280px) {
    margin-right: 8rem;
  }
}

.hays-footer .hays-footer__social-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1.5rem;
}

.hays-footer .hays-footer__our-partners {
  column-gap: 3rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 0.5rem;

  @media screen and (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.hays-footer .hays-footer__our-partners-title {
  grid-column: span 3 / span 3;

  color: var(--selective-yellow);
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0;
  font-weight: 700;
}

.heys-footer .hays-footer__our-partners-icon {
  height: 4.5rem;
  width: 4.5rem;

  @media screen and (min-width: 1280px) {
    height: 6rem;
    width: 6rem;
  }
}
