@use './src/variables' as *;

.section-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #cddae5;
  border-radius: 12px;
  padding: 2rem;

  p {
    margin: 1rem 0 0;
    font-weight: 600;
    color: $main;
  }
}
