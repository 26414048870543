@use './src/variables' as *;

.extras-card-list {
  &--item {
    margin-bottom: 10px;
  }
}

.summary-card-wrapper {
  border: solid 1px black;
  border-radius: 10px;
  padding: 30px 20px;
  // color: $main;

  &--header {
    display: flex;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
    }

    .card-title {
      font-size: 14px;
      font-weight: bold;
      margin-left: 15px;
    }
  }

  &--body {
    margin-top: 20px;

    .summary-card-list {
      font-size: 12px;

      .summary-card-value {
        white-space: pre-line;
      }

      &--item {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .light-title {
          color: $secondary-light;
        }
      }

      .summary-card-list--item + .summary-card-list--item {
        margin-top: 20px;
      }

      .separator {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 3px 0;
      }
    }

    .summary-divider {
      margin: 15px 0;
      width: 100%;
      height: 1px;
      background-color: $grey-light;
    }

    .card-title {
      display: block;
      margin: 0 0 20px 0;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

@media (max-width: $screen-small) {
  .summary-card-wrapper {
    border: none;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
}
