@import '../../variables';

.tripadvisor-votes-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;

  .tripadvisor-votes-score-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tripadvisor-votes-score-list-item {
      display: flex;

      .item-name {
        font-size: 12px;
        color: $grey;
        width: 40%;
        min-width: 125px;
      }

      .rating {
        margin-left: 0;
      }
    }
  }
}

@media (min-width: $screen-medium) {
  .tripadvisor-votes-list-wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0;

    .tripadvisor-votes-score-list {
      &.right {
        margin-right: 150px;
      }

      &.left {
        gap: 12px;
      }

      .tripadvisor-votes-score-list-item {
        display: flex;

        .item-name {
          font-size: 16px;
        }
      }
    }
  }
}
