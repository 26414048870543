@import '../../variables';

.modal-with-tabs {
  .modal-content {
    display: flex;
    flex-direction: column;
  }

  .modal-layout__content {
    height: 100% !important;

    .modal-content {
      height: calc(100% - 56px) !important;
    }
  }

  .modal-window {
    // max-height: 90vh;
    // min-height: 100vh;
    .hotel-information-wrapper {
      // color: $main;

      .hotel-information-slider-wrapper {
        max-width: 1024px;
        margin: 1rem auto 0;
        height: 100%;
      }
    }
  }
}

.simple-tabs-list {
  display: flex;
  justify-content: space-between;
  margin: 12px;
  overflow-x: auto;

  .simple-tabs-tab {
    cursor: pointer;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    border-bottom: 5px solid transparent;

    .tab-name {
      margin-left: 8px;
      font-size: 12px;

      @media screen and (min-width: 560px) {
        font-size: 1rem;
      }
    }

    &.active {
      border-bottom: 5px solid $main;
    }
  }
}

.simple-tabs-tab-content {
  margin: 16px;
  display: block;
  // overflow: hidden;
  // overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;

  // &.visible {
  // background-color: #fff;
  //height: calc(100% - 142px);
  // position: relative;
  // z-index: 9999;
  // }

  &.hidden {
    display: none;
  }
}

@media (min-width: $screen-small) {
  .modal-with-tabs {
    .modal-window {
      @media screen and (min-width: 1200px) {
        height: 90vh;
        max-height: 90vh;
      }
    }
  }

  .simple-tabs-list {
    // margin: 12px 75px;
    margin: 12px 24px;

    .simple-tabs-tab {
      // padding: 24px 8px;
      // font-size: 20px;
      font-size: 1rem;
    }
  }

  .simple-tabs-tab-content {
    // margin: 32px 60px 70px;
    margin: 12px 24px 24px;
    border-radius: 6px;

    // &.visible {
    //height: calc(100% - 85px);
    // }

    // &.tab-1 {
    //height: 918px;
    // }
  }
}
