@use './src/variables' as *;

.holiday-page-loader {

  .refine-holiday {
    display: flex;
    flex-direction: column;
    gap: 44px;
  }

  .fake-image {
    aspect-ratio: 4/3;
    background-color: $secondary-light;
    border-radius: 5px;
    margin: 2em 0;
  }

  .fake-calendar {
    background-color: $secondary-light;
    border-radius: 12px;
    height: 543px;
  }

  .fake-paragraph {
    margin-left: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: $secondary-light;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .fake-paragraph-xs {
    width: 62px;
  }
  .fake-paragraph-s {
    width: 144px;
  }
  .fake-paragraph-m {
    width: 187px;
  }
  .fake-paragraph-l {
    width: 239px;
  }

  .one-line-fake-paragraph {
    display: flex;
  }

  .select-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }

  .select-option {
    height: 68px;
    border-radius: 6px;
    background-color: $secondary-light;
  }

  .holiday-card-container {
    width: 350px;
    border-radius: 6px;
    background-color: $secondary-light;
  }

  .gradient-animation {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, $white 80%, #f1f5f9 100%, #f1f5f9 85%);
    background-size: 500px 500px;

    position: relative;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 0px 0;
    }
  }
}
