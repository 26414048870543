@use './src/variables' as *;

.holiday-details-wrapper {
  padding: 20px 25px;
  font-family: $base-font;

  .holiday-details {
    &--item {
      display: flex;
      align-items: center;
      padding: 2px 0;
      // color: $main;
      font-size: 14px;
      font-weight: 300;
      position: relative;

      img {
        width: 17px;
        height: 17px;
      }

      span {
        margin-left: 20px;
      }

      .flight {
        transform: scale(2) rotate(90deg);
      }

      .flight-details {
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0;
        text-decoration-thickness: from-font;
      }

      .flight-info {
        img {
          width: auto;
          height: 30px;
        }
      }
    }
  }

  &.holiday-details-wrapper--grid {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: $screen-small) {
    padding: 20px 30px;
  }
}
