@import '../../variables';

.select-address-custom-option {
  padding: 8px;
  border: 1px solid $secondary;
}

.lead-passenger-information {
  .lead-passenger-title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .lead-passenger-title-text {
      // color: $main;
      font-weight: bold;
      margin-left: 8px;
    }

    &.lead-passenger-title--room {
      margin-top: 0;
      margin-bottom: 1rem;
      border-bottom: 2px solid #cddae5;
      padding-bottom: 1rem;

      .lead-passenger-title-text {
        margin-left: 0;
      }
    }
  }

  .passenger-attention {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .passenger-attention-text {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  .passenger-input-wrapper {
    margin: 8px 0 20px 0;
    position: relative;

    .passenger-info-input {
      width: 100%;
      font-size: 16px;
      border: 1px solid $secondary;
      border-radius: 4px;
      min-height: 38px;
      padding: 2px 8px;
      box-sizing: border-box;

      &:focus {
        outline: none !important;
      }
    }
    .error-message {
      display: none;
      position: absolute;
      bottom: -16px;
      left: 0;
      font-size: 12px;
      font-weight: 100;
      color: red;
    }
  }
  .select-label,
  .dob-inputs-title {
    font-weight: bold;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .autocomplete-custom-checkbox-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;

      .autocomplete-custom-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .autocomplete-custom-checkbox-checkmark {
        background-color: $white;
        border-radius: 2px;
        border: 1px solid $secondary;
        height: 24px;
        width: 24px;
        position: absolute;
        top: -7px;
        left: 0;

        &:after {
          content: '';
          position: absolute;
          display: none;
        }
      }
    }

    .autocomplete-custom-checkbox:checked ~ .autocomplete-custom-checkbox-checkmark {
      background-color: $main;
    }

    .autocomplete-custom-checkbox:checked ~ .autocomplete-custom-checkbox-checkmark:after {
      display: block;
    }

    .autocomplete-custom-checkbox-container .autocomplete-custom-checkbox-checkmark:after {
      left: 9px;
      top: 5px;
      width: 3px;
      height: 8px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
    .text-above-checkbox {
      font-size: 14px;
    }
  }
  .postcode-wrapper {
    margin-top: 24px;

    .passenger-postcode {
      display: flex;
      gap: 12px;
    }
  }
  .addresses-select {
    margin: 24px 0;
  }
}

.passengers-details-container {
  &.invalid {
    input {
      &:invalid {
        & ~ .error-message {
          display: block;
        }
      }
    }
  }
}

@media (min-width: $screen-small) {
  .lead-passenger-information {
    .passenger-email {
      width: calc(50% + 12px);
    }
    .passenger-confirm-email {
      width: 50%;
    }
    .postcode-wrapper {
      .passenger-postcode {
        width: 50%;
      }
    }
    .addresses-select {
      width: 50%;
    }
    .address-fields-wrapper {
      width: 50%;
    }
  }
}
