/**
 * Direct Debit Form Styles
 */

.dd-form {
  .dd-form__split-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}
