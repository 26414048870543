@import '../../../variables';

.hwt-custom-select {
  display: inline-block;
  font-family: $base-font;
  color: $main;
  background-color: white;

  &--title {
    display: flex;
    border: solid 1px $main;
    border-radius: 5px;
    cursor: pointer;

    span {
      display: inline-block;
      padding: 15px 95px;
      font-size: 14px;
      font-weight: 300;
      border-right: solid 1px $main;
      white-space: nowrap;
      text-align: center;

      @media (max-width: $screen-small) {
        padding: 15px 50px;
      }
    }

    img {
      height: 10px;
      width: 20px;
      margin: auto 13px;
    }
  }

  &--items {
    position: absolute;
    width: 100%;
    top: 100%;
    background: white;

    list-style: none;
    height: 150px;
    overflow-y: scroll;
    padding: 0;
    margin: 3px 0 0 0;
    border: solid 1px $main;
    border-radius: 5px;
  }

  &--item {
    padding: 5px;
    cursor: pointer;
  }

  &--item:hover {
    background-color: $background-light;
  }

  .active {
    background-color: $secondary-light;
  }
}
